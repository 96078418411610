import { Select } from "antd";
import { useProjectParticipants } from "hooks/project-participants";

const { Option } = Select;

function ResponsibleDesignFirmsDropdown(props: any) {
  const { value, onChange } = props;
  const { projectParticipants } = useProjectParticipants();
  const designFirms = projectParticipants?.drCompanies;

  return (
    <Select
      value={value}
      onChange={onChange}
      showSearch
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
    >
      {designFirms.map((cmpny: any) => {
        return (
          <Option
            key={cmpny.vendor_id}
            value={cmpny.vendor_id}
            label={`${cmpny?.subscription_vendor?.name}`}
          >
            <div className="text-[14px] text-[#3b3b3b]">
              {cmpny?.subscription_vendor?.name}
            </div>
          </Option>
        );
      })}
    </Select>
  );
}

export default ResponsibleDesignFirmsDropdown;
