import { gql } from "@apollo/client";

const QUERY_GET_PROJECT_LIST = gql`
  query ProjectList($where: project_list_view_bool_exp = {}) {
    project_list_func(where: $where) {
      id
      status_id
      name
      start_date
      end_date
      details
      address
      number
      user_type_id
      mode
    }
  }
`;

const QUERY_PROJECT_WORKFLOW_TEMPLATES_ID_NAME = gql`
  query GetTemplates {
    project_workflow_templates(where: { feature_id: { _eq: 2 } }) {
      id
      name
    }
  }
`;

const QUERY_GET_MDB_TEMPLATES = gql`
  query GetMDBTemplates {
    project_workflow_templates(where: { feature_id: { _eq: 2 } }) {
      id
      name
      default
      disabled
      feature_id
      additional_offset
      float
      project_template_milestones(order_by: { sequence_no: asc }) {
        id
        sequence_no
        name
        milestone_action
        next_offset {
          name
          id
          duration
        }
      }
      updated_by
      updated_by_user {
        first_name
        email
        last_name
        id
      }
      updated_at
      created_by_user {
        email
        first_name
        last_name
      }
      created_at
      date_blocks_aggregate(
        where: { workflow_status: { _eq: "IN_PROGRESS" } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

const QUERY_PROJECT_FEATURE_CONFIGURATION = gql`
  query featureConfigurationThreshold(
    $where: project_feature_configurations_bool_exp = {}
  ) {
    project_feature_configurations(where: $where) {
      feature_id
      high_risk_threshold
      low_risk_threshold
    }
  }
`;

const QUERY_DISTRIBUTION_GROUP_LIST = gql`
  query DistributionGroups($where: distribution_group_bool_exp!) {
    distribution_group(where: $where) {
      name
      id
      distribution_group_user {
        user {
          id
          email
          first_name
          last_name
          project_users {
            status_id
            project_role {
              name
            }
            subscription {
              organization_subscriptions {
                organization {
                  name
                }
              }
            }
            subscription_vendor {
              name
            }
          }
        }
      }
    }
  }
`;

const QUERY_GET_SUBMITTAL_DL_USERS = gql`
  query fetchSubmittalDistributionList(
    $where: submittal_distribution_list_bool_exp!
  ) {
    submittal_distribution_list(where: $where) {
      user_id
      dl_id
    }
  }
`;

const QUERY_USER_CONFIG_VIEWS = gql`
  query getAllUserView($feature_id: Int!) {
    project_feature_user_view(where: { feature_id: { _eq: $feature_id } }) {
      id
      name
      column_config
    }
  }
`;

const QUERY_SUBMITTAL_LIST_INTEGRATION_MODE = gql`
  query SubmittalListIntegrationModeBenchmarking {
    submittal_list_intg_mode_func_benchmarking {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
    }
  }
`;

const QUERY_GET_PROJECT_SPECS = gql`
  query Specs {
    spec_sections(
      order_by: { section_number: asc }
      distinct_on: section_number
    ) {
      title
      id
      section_number
      file_key
    }
  }
`;

const QUERY_GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organization {
      id
      name
      domain
    }
  }
`;

const QUERY_GET_SUBMITTAL_STATUSES = gql`
  query GetSubmittalStatus {
    form_status {
      id
      status
    }
  }
`;

const QUERY_GET_TIMEZONES = gql`
  query MyTimeZoneQuery {
    timezones {
      id
      timezone_id
      name
    }
  }
`;

export const QUERY_GET_SUBMITTALS_NAME_AND_ID = gql`
  query GetSubmittal($where: submittals_bool_exp!) {
    submittals(where: $where, order_by: { submittal_id: asc }) {
      submittal_id
      id
      title
    }
  }
`;

const QUERY_GET_SUBMITTAL_TYPES = gql`
  query GetSubmittalTypes {
    configurations_value {
      value
      id
      configuration_master_id
    }
  }
`;

const QUERY_USERS = gql`
  query subscription_users {
    subscription_users {
      user_id
      user {
        first_name
        last_name
        email
        phone
      }
      subscription_permission {
        name
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
    }
  }
`;

const QUERY_GET_VENDOR_BY_PK = gql`
  query subscriptionVendorsByPk($id: uuid!) {
    subscription_vendors_by_pk(id: $id) {
      name
      id
      type_id
      trade_partner_pocs {
        id
        email
        first_name
        last_name
        phone
      }
    }
  }
`;

const QUERY_GET_SUBMITTAL_BY_TITLE = gql`
  query GetSubmittalByTitle($title: String!) {
    submittals(where: { title: { _eq: $title } }) {
      id
    }
  }
`;

const QUERY_PROJECT_DETAILS_PK = gql`
  query ProjectDetails($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      id
      details
      name
      start_date
      updated_at
      updated_by
      status_id
      end_date
      created_by
      created_at
      timezone {
        timezone_id
        name
      }
    }
  }
`;

const QUERY_PROJECT_AGG = gql`
  query GetProjectAggByName($where: project_bool_exp!) {
    project_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const QUERY_TRADE_USER_TYPE = gql`
  query GetTradeUserTypes {
    user_types(where: { key: { _neq: "GENERAL_CONTRACTOR" } }) {
      id
      key
      name
    }
  }
`;

const QUERY_GET_USER_TYPE_BY_KEY = gql`
  query MyQuery($key: String) {
    user_types(where: { key: { _eq: $key } }) {
      id
      key
      name
    }
  }
`;

const QUERY_GET_VENDOR_ID = gql`
  query GetVendorIdQuery($userId: uuid!) {
    project_users(where: { user_id: { _eq: $userId } }) {
      subscription_vendor {
        id
      }
    }
  }
`;
const QUERY_GET_SPEC_SECTION_BY_SECTION_NUMBER = gql`
  query GetSpecSectionByNumber($sectionNumber: String!) {
    spec_sections(where: { section_number: { _eq: $sectionNumber } }) {
      section_number
      section_name
      title
    }
  }
`;

const QUERY_GET_PROJECT_USER_STATUS_ID = gql`
  query PrjectUsersQuery($where: project_users_bool_exp!) {
    project_users(where: $where) {
      status_id
      user {
        email
      }
    }
  }
`;

const QUERY_GET_SUBSCRIPTION_USER_STATUS_ID = gql`
  query SubscriptionUsersQuery($where: subscription_users_bool_exp!) {
    subscription_users(where: $where) {
      status_id
      user {
        email
      }
    }
  }
`;

const QUERY_GET_MATERIAL = gql`
  query GetMaterial($where: material_bool_exp) {
    material(where: $where) {
      id
    }
  }
`;

const QUERY_NON_SCHEDULE_IMPORT_PERMISSION_USR_GANTT_TASKS = gql`
  query GetSchedulerList {
    gantt_tasks {
      id
      type
      text
      duration
      end_date
      start_date
      source_task_id
      import_log_id
      parent
    }
  }
`;

const QUERY_GANTT_TASKS_LIST = gql`
  query GetSchedulerList {
    gantt_tasks {
      id
      type
      text
      duration
      end_date
      start_date
      source_task_id
      import_log_id
      parent
      submittal_id
      submittal {
        title
        description
        assignee
        workflow_status
      }
      material_id
      material {
        name
        material_assignee {
          id
        }
        status
      }
    }
  }
`;

const QUERY_SCHEDULER_TASK_LINK_LIST = gql`
  query GetSchedulerTaskLinkList {
    gantt_links {
      id
      source
      target
      type
    }
  }
`;

const QUERY_GET_SUBSCRIPTION_DETAILS = gql`
  query GetSubscriptionDetail($id: uuid!) {
    subscription_by_pk(id: $id) {
      id
      name
      paid
    }
  }
`;

const QUERY_SUBMITTAL_WORKFLOW_RESPONSES = gql`
  query SubmittalReviewResponse {
    submittal_response {
      key
      response
      id
    }
  }
`;

const QUERY_MASTER_UNITS = gql`
  query UnitQuery {
    material_quantity_unit {
      id
      measurement
      unit
    }
    material_size_unit {
      id
      measurement
      unit
    }
  }
`;

const QUERY_SUBMITTAL_TYPES = gql`
  query SubmittalTypes {
    configurations_value(order_by: { value: asc }) {
      value
      id
      configuration_master_id
    }
  }
`;

const QUERY_GET_SUBMITTALL_DISTRIBUTION_LIST = gql`
  query GetSubmittalDistributionList(
    $where: submittal_distribution_list_bool_exp!
  ) {
    submittal_distribution_list(where: $where) {
      dl_id
      user_id
      submittal_id
    }
  }
`;

const QUERY_PROJECT_USERS = gql`
  query ProjectUsers($where: project_users_bool_exp) {
    project_users(where: $where, order_by: { updated_at: desc }) {
      updated_at
      user_id
      subscription_id
      user_type {
        id
        name
      }
      user {
        email
        first_name
        id
        status_id
        last_name
        phone
      }
      project_role {
        id
        key
        name
      }
      subscription_vendor {
        id
        name
        partner_subscription_id
        project_vendors {
          type_id
          project_vendors_company_status {
            status
          }
          project_vendors_type {
            key
            name
            id
          }
        }
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
      status_id
      external
      external_source_id
      project_integration_users {
        full_source_name
      }
    }
  }
`;

const QUERY_SUBMITTALS_LINKING_TABLE = gql`
  query SubmittalsLinking {
    submittals(where: { deleted: { _eq: false } }) {
      id
      submittal_id
      title
      description
      spec_name
      spec_no
      material_tracking
      status
      submittal_type {
        id
        value
      }
      submittal_schedule_links {
        linked_to_end_date
        gantt_task {
          id
          source_task_id
          text
        }
      }
      submittal_material_links {
        material {
          spec_section_name
          spec_section_no
          name
          material_id
          id
          actual_ROJ
          implicit
          actual_fabrication_start_date
          actual_material_release_date
          actual_shipment_date
          material_schedule_links {
            linked_to_end_date
            gantt_task {
              id
              source_task_id
              text
            }
          }
          material_date_block {
            ROJ_date
            fabrication_duration_offset
            fabrication_start_date
            lead_time_offset
            material_PO_date
            material_PO_fabrication_offset
            on_site_storage_offset
            shipment_date
            material_id
          }
        }
      }
      submittal_date_block {
        ext_review_offset
        final_gc_review_offset
        gc_review_offset
        submittal_prep_offset
        float
        assigned_date
        ext_review_completed_date
        ext_review_submitted_date
        submittal_distributed_date
        trade_partner_submitted_date
        submittal_id
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
      }
    }
  }
`;

const QUERY_PROJECT_MATERIALS = gql`
  query ProjectMaterials {
    material(where: { implicit: { _neq: true } }) {
      id
      material_id
      name
      spec_section_no
      spec_section_name
      actual_material_release_date
      material_schedule_links {
        linked_to_end_date
        gantt_task {
          id
          source_task_id
          text
        }
      }
    }
  }
`;

const QUERY_ACTIVE_PROJECT_USERS = gql`
  query ProjectUsers($where: project_users_bool_exp!) {
    project_users(where: $where) {
      status_id
      user {
        email
        first_name
        id
        status_id
        last_name
        phone
      }
    }
  }
`;

const QUERY_GANTT_TASKS_LIST_FOR_PROCUREMENT = gql`
  query GetSchedulerList {
    gantt_tasks {
      id
      type
      text
      duration
      end_date
      start_date
      source_task_id
      submittal_schedule_links {
        submittal_id
        submittal {
          id
          submittal_id
          spec_no
          title
          revision
          workflow_status
          assignee
          gc_reviewer
          design_reviewer
          responsible_contractor
          gc_reviewer_user {
            id
            first_name
            last_name
          }
          design_reviewer_user {
            id
            first_name
            last_name
          }
          responsible_contractor_vendor {
            id
            name
          }
          submittal_assignee {
            email
            first_name
            last_name
          }
          responsible_contractor
          responsible_contractor_vendor {
            id
            address
            name
            vendor_no
          }
          submittal_date_block {
            assigned_date
            submittal_distributed_date
          }
          submittal_material_links {
            material_id
          }
          date_block_submittals {
            planned_milestone_1
            planned_milestone_5
          }
        }
      }
      material_schedule_links {
        linked_to_end_date
        material_id
        material {
          id
          material_id
          name
          assignee
          status
          material_assignee {
            first_name
            last_name
          }
          material_date_block {
            material_PO_date
            ROJ_date
          }
          date_block_materials {
            planned_milestone_1
            planned_milestone_4
          }
          implicit
          submittal_material_links {
            submittal_id
            submittal {
              id
              submittal_id
              spec_no
              title
              revision
              workflow_status
              assignee
              gc_reviewer
              design_reviewer
              responsible_contractor
              gc_reviewer_user {
                id
                first_name
                last_name
              }
              design_reviewer_user {
                id
                first_name
                last_name
              }
              responsible_contractor_vendor {
                id
                name
              }
              submittal_assignee {
                email
                first_name
                last_name
              }
              responsible_contractor
              responsible_contractor_vendor {
                id
                address
                name
                vendor_no
              }
              submittal_date_block {
                assigned_date
                submittal_distributed_date
              }
            }
          }
        }
      }
    }
  }
`;

const QUERY_CALENDAR_HOLIDAY_LIST = gql`
  query CalendarHolidayList($projectId: uuid) {
    gantt_calendar(
      where: { deleted: { _eq: false }, project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      calendar_holiday_lists(where: { deleted: { _eq: false } }) {
        date
      }
    }
  }
`;

const QUERY_SUBMITTAL_DATE_BLOCK_BY_MATERIAL_ID = gql`
  query MyQuery($materialId: uuid = "") {
    submittals(
      where: {
        submittal_material_links: { material: { id: { _eq: $materialId } } }
      }
    ) {
      submittal_date_block {
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
      }
      status
    }
  }
`;
const QUERY_PROJECT_DETAILS_PK_SETTINGS = gql`
  query ProjectDetails($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      id
      mode
      spec_section
      name
      timezone {
        timezone_id
        name
      }
    }
  }
`;

const QUERY_USER_PROFILE_INFO = gql`
  query UserProfileInfo($where: subscription_users_bool_exp) {
    subscription_users(where: $where) {
      user {
        first_name
        last_name
        email
        phone
      }
      subscription {
        name
      }
    }
  }
`;

const QUERY_PROJECT_VENDORS = gql`
  query ProjectQuery($where: project_vendors_bool_exp = {}) {
    project_vendors(where: $where) {
      vendor_id
      subscription_vendor {
        partner_subscription_id
        name
        project_users {
          external
        }
      }
      type_id
      project_vendors_type {
        id
        name
        key
      }
      status
      project_vendors_company_status {
        status
        id
      }
    }
  }
`;

const QUERY_COMPANY_LIST = gql`
  query CompanyList($where: subscription_vendors_bool_exp = {}) {
    subscription_vendors(where: $where, order_by: { name: asc }) {
      id
      name
      address
      trade_partner_pocs(where: { deleted: { _eq: false } }) {
        email
        first_name
        last_name
        phone
        id
      }
      project_vendors {
        type_id
        project_vendors_company_status {
          status
        }
      }
    }
  }
`;

const QUERY_GET_ALL_MATERIAL_LINKED_WITH_SUBMITTAL = gql`
  query fetch_submittal_material_links {
    submittal_material_link {
      driving_material
      submittal_id
      material {
        implicit
        description
        material_id
        name
        id
      }
    }
  }
`;

const QUERY_PROJECT_INTEGRATION = gql`
  query GetProjectIntegrationKey {
    external_integrations {
      integration_key
      configuration
    }
  }
`;

const QUERY_PROJECT_INTEGRATION_USERS = gql`
  query GetProjectIntegrationUser {
    project_integration_users {
      id
      email
      first_name
      last_name
      vendor_id
      user {
        project_users {
          status_id
        }
      }
      project_integration_vendor {
        id
        name
      }
    }
  }
`;

const QUERY_PROJECT_CALENDAR = gql`
  query ProjectCalendar($projectId: uuid) {
    gantt_calendar(
      where: { deleted: { _eq: false }, project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      id
      name
      calendar_holiday_lists(where: { deleted: { _eq: false } }) {
        date
      }
      calendar_shift_mapping {
        id
        deleted
        work_day_mapping {
          name
        }
      }
    }
  }
`;

const QUERY_PRODUCT_ENTITLEMENTS = gql`
  query ProductEntitlementsQuery {
    product_entitlements {
      id
      feature
      enabled
    }
  }
`;

const QUERY_SAVED_COLUMN_HEADERS = gql`
  query SavedColumnHeaders($where: project_users_bool_exp) {
    project_users(where: $where) {
      project_id
      submittal_column_config
      material_column_config
    }
  }
`;

const QUERY_CALENDAR_LIST = gql`
  query CalendarList($projectId: uuid) {
    gantt_calendar(
      where: { deleted: { _eq: false }, project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      id
      name
      description
      calendar_holiday_lists(where: { deleted: { _eq: false } }) {
        id
        country_code
        holiday_name
        date
      }
      work_shifts {
        id
        hours
        name
      }
      calendar_shift_mapping {
        id
        deleted
        work_day
        work_day_mapping {
          name
        }
        work_shift {
          name
          hours
        }
      }
    }
  }
`;

const QUERY_SUBMITTAL_RISK_REPORT_WF = gql`
  query SubmittalRiskReportWF(
    $where: submittal_cumulative_risk_report_wf_mode_view_interface_bool_exp
  ) {
    submittal_cumulative_risk_report_wf_mode_func(
      where: $where
      order_by: { submittal_id: asc }
    ) {
      id
      submittal_id
      assignee_user_id
      assignee_unregistered
      assignee_status
      date_block_submittals
      project_id
      responsible_contractor_id
      responsible_contractor_name
      spec_name
      spec_no
      status
      submittal_material_links
      submittal_schedule_links
      workflow_status
      title
      type
      history
      submitter_user_id
      gc_reviewer_user_id
      design_reviewer_user_id
      design_reviewer_unregistered
      submitter_unregistered
      risk_level
    }
  }
`;
const QUERY_SUBMITTAL_RISK_REPORT_INTG = gql`
  query SubmittalRiskReportWF(
    $where: submittal_cumulative_risk_report_intg_mode_view_interface_bool_exp
  ) {
    submittal_cumulative_risk_report_intg_mode_func(
      where: $where
      order_by: { submittal_id: asc }
    ) {
      id
      submittal_id
      assignee_user_id
      assignee_status
      date_block_submittals
      project_id
      responsible_contractor_id
      responsible_contractor_name
      spec_name
      spec_no
      submittal_material_links
      submittal_schedule_links
      workflow_status
      title
      type
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      submitter_user_id
      gc_reviewer_user_id
      design_reviewer_user_id
      risk_level
    }
  }
`;

const QUERY_RISK_REPORT_SUMMARY = gql`
  query RiskReportSummary {
    project_gc_cumulative_risk_report_summary_func {
      item_type
      risk_level
      count
    }
  }
`;

const QUERY_MATERIAL_RISK_REPORT = gql`
  query MaterialRiskReport(
    $where: material_cumulative_risk_report_view_interface_bool_exp
  ) {
    material_cumulative_risk_report_func(
      where: $where
      order_by: { material_id: asc }
    ) {
      assignee
      assignee_first_name
      assignee_last_name
      assignee_status
      date_block_materials
      id
      material_id
      material_schedule_links
      material_submitttal_links
      material_tag
      name
      project_id
      risk_level
      spec_section_name
      spec_section_no
      status
      trade_partner_name
      gc_representative
      gc_representative_first_name
      gc_representative_last_name
      gc_representative_status
    }
  }
`;

export {
  QUERY_GET_ORGANIZATIONS,
  QUERY_GET_SUBMITTAL_STATUSES,
  QUERY_GET_SUBMITTAL_TYPES,
  QUERY_USERS,
  QUERY_GET_SUBMITTAL_BY_TITLE,
  QUERY_GET_VENDOR_BY_PK,
  QUERY_PROJECT_DETAILS_PK,
  QUERY_PROJECT_AGG,
  QUERY_TRADE_USER_TYPE,
  QUERY_GET_USER_TYPE_BY_KEY,
  QUERY_GET_VENDOR_ID,
  QUERY_GET_SPEC_SECTION_BY_SECTION_NUMBER,
  QUERY_GET_PROJECT_USER_STATUS_ID,
  QUERY_GET_SUBSCRIPTION_USER_STATUS_ID,
  QUERY_GET_MATERIAL,
  QUERY_GANTT_TASKS_LIST,
  QUERY_SCHEDULER_TASK_LINK_LIST,
  QUERY_GET_SUBSCRIPTION_DETAILS,
  QUERY_GET_SUBMITTALL_DISTRIBUTION_LIST,
  QUERY_GET_PROJECT_LIST,
  QUERY_PROJECT_USERS,
  QUERY_SUBMITTAL_WORKFLOW_RESPONSES,
  QUERY_SUBMITTAL_TYPES,
  QUERY_MASTER_UNITS,
  QUERY_SUBMITTALS_LINKING_TABLE,
  QUERY_PROJECT_MATERIALS,
  QUERY_ACTIVE_PROJECT_USERS,
  QUERY_GET_TIMEZONES,
  QUERY_GANTT_TASKS_LIST_FOR_PROCUREMENT,
  QUERY_CALENDAR_HOLIDAY_LIST,
  QUERY_SUBMITTAL_DATE_BLOCK_BY_MATERIAL_ID,
  QUERY_NON_SCHEDULE_IMPORT_PERMISSION_USR_GANTT_TASKS,
  QUERY_PROJECT_DETAILS_PK_SETTINGS,
  QUERY_USER_PROFILE_INFO,
  QUERY_PROJECT_VENDORS,
  QUERY_COMPANY_LIST,
  QUERY_GET_ALL_MATERIAL_LINKED_WITH_SUBMITTAL,
  QUERY_PROJECT_INTEGRATION,
  QUERY_PROJECT_INTEGRATION_USERS,
  QUERY_SUBMITTAL_LIST_INTEGRATION_MODE,
  QUERY_PROJECT_CALENDAR,
  QUERY_PRODUCT_ENTITLEMENTS,
  QUERY_DISTRIBUTION_GROUP_LIST,
  QUERY_GET_SUBMITTAL_DL_USERS,
  QUERY_PROJECT_FEATURE_CONFIGURATION,
  QUERY_USER_CONFIG_VIEWS,
  QUERY_GET_PROJECT_SPECS,
  QUERY_GET_MDB_TEMPLATES,
  QUERY_PROJECT_WORKFLOW_TEMPLATES_ID_NAME,
  QUERY_SAVED_COLUMN_HEADERS,
  QUERY_CALENDAR_LIST,
  QUERY_SUBMITTAL_RISK_REPORT_WF,
  QUERY_SUBMITTAL_RISK_REPORT_INTG,
  QUERY_RISK_REPORT_SUMMARY,
  QUERY_MATERIAL_RISK_REPORT
};
