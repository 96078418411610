import { DatePicker } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import moment, { Moment } from "moment";

import { useCallback, useContext, useMemo } from "react";

type DatePickerProps = {
  className?: string;
  placeholder?: string;
  allowClear?: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
  format?: string;
  inputReadOnly?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  value?: Moment | null | undefined;
  onChange?: any;
  disabledDate?: (current: Moment) => boolean;
  renderExtraFooter?: any;
  style?: any;
  bordered?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

function CIQDatePicker(props: DatePickerProps) {
  const {
    className = "h-0 w-0 p-0 m-0 border-0 border-none bg-white",
    placeholder,
    allowClear,
    defaultOpen,
    disabled,
    format,
    inputReadOnly,
    open,
    onOpenChange,
    value,
    onChange,
    disabledDate,
    renderExtraFooter,
    style,
    bordered = true,
    onFocus,
    onBlur
  } = props;

  const { calHolidaysWeekends }: TProjectContext = useContext(ProjectContext);

  const holidays: any = useMemo(
    () => calHolidaysWeekends?.holidays,
    [calHolidaysWeekends?.holidays]
  );

  const weekends: any = useMemo(
    () => calHolidaysWeekends?.weekends,
    [calHolidaysWeekends?.weekends]
  );

  const checkDisablesDates = useCallback(
    (current: Moment) => {
      let disableDateResult = false;
      let disableHolidaysResult = false;
      let disableWeekendsResult = false;
      if (disabledDate) {
        disableDateResult = disabledDate(current);
      }
      if (holidays) {
        disableHolidaysResult = !!holidays.find((hld: any) =>
          current.isSame(moment(hld?.date), "D")
        );
      }

      if (weekends) {
        disableWeekendsResult = weekends.some((wkend: number) => {
          return current.day() === wkend;
        });
      }

      return (
        disableDateResult || disableHolidaysResult || disableWeekendsResult
      );
    },
    [holidays, disabledDate, weekends]
  );

  return (
    <DatePicker
      className={className}
      placeholder={placeholder}
      defaultOpen={defaultOpen}
      disabled={disabled}
      allowClear={allowClear}
      format={format}
      inputReadOnly={inputReadOnly}
      open={open}
      onOpenChange={onOpenChange}
      value={value}
      onChange={onChange}
      disabledDate={checkDisablesDates}
      renderExtraFooter={renderExtraFooter}
      style={style}
      bordered={bordered}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

export default CIQDatePicker;
