import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FilterChips } from "components";
import { FilterItem } from "models/types";
import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";

type Props = {
  gridRef: React.RefObject<AgGridReact<any>>;
  columnDefs: (ColDef<any> | ColGroupDef<any>)[];
};

type FilterChipHandle = {
  onFilterApplied(): void;
};

export const FilterChipComponent = forwardRef<FilterChipHandle, Props>(
  (props, ref) => {
    const { gridRef, columnDefs } = props;
    const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
    const [customDateFilter, setCustomDateFilter] = useState<any>({});

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      onFilterApplied() {
        const filtersApplied = gridRef.current?.api.getFilterModel();
        if (filtersApplied) {
          const items: FilterItem[] = new Array<FilterItem>();
          Object.keys(filtersApplied).forEach((key: any) => {
            if (
              filtersApplied[key]?.values &&
              filtersApplied[key].values.length > 0
            ) {
              const field = columnDefs!.filter((x: any) => x.colId === key)[0];
              const { values, range } = filtersApplied[key];

              items.push({
                field: key,
                header: field ? field.headerName : key,
                value: values,
                range
              });
            }
          });
          setFilterItems(items);
        }
      }
    }));

    const onCategoryDelete = (item: FilterItem) => {
      gridRef.current?.api.destroyFilter(item.field);
    };

    const onFilterChipDelete = (item: FilterItem) => {
      if (item.field === customDateFilter.field) {
        const index = filterItems.findIndex(
          (val) => val.field === customDateFilter.field
        );
        if (index > -1) {
          // eslint-disable-next-line no-param-reassign
          delete filterItems[index];
        }
        setFilterItems([...filterItems].filter(Boolean));
        setCustomDateFilter({});
        gridRef.current?.api.destroyFilter(customDateFilter.field);
      } else {
        const filterInstance = gridRef.current?.api.getFilterInstance(
          item.field
        );
        if (filterInstance) {
          const model = filterInstance.getModel();
          const values = model.values.filter((val: any) => val !== item.value);
          if (values.length === 0) {
            gridRef.current?.api.destroyFilter(item.field);
            return;
          }
          filterInstance.setModel({ values });
          gridRef.current?.api.onFilterChanged();
        }
      }
    };

    const resetFilters = () => {
      gridRef.current?.api.setFilterModel(null);
    };

    if (filterItems && filterItems.length)
      return (
        <FilterChips
          items={filterItems}
          onChipDelete={onFilterChipDelete}
          onCategoryDelete={onCategoryDelete}
          resetAll={resetFilters}
        />
      );
    return null;
  }
);

export type FilterChipComponentRef = ComponentRef<typeof FilterChipComponent>;
