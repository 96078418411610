import { Card } from "antd";
import "./editable-details-card-wrapper.scss";

function EditableDetailsCardWrapper(props: any) {
  const { title, children } = props;
  return (
    <Card className="ciq-card-block overflow-hidden">
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        {title}
      </div>
      <div className="grow overflow-auto">{children}</div>
    </Card>
  );
}

export default EditableDetailsCardWrapper;
