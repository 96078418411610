/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-plusplus */
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { RiskLevelType } from "components/date-block/models";
import ScheduleCellRenderer from "./schedule-cell-renderer/schedule-cell-renderer";
import "./submittal-schedule-impact.scss";
import GoverningItemCellRenderer from "../governing-item-cell-renderer/governing-item-cell-renderer";
import { headerHeight, maxRowCount, rowHeight } from "../utils";

function SubmittalScheduleImpact(props: {
  linkedSubmittals: [];
  isDeleted: boolean;
}) {
  const { linkedSubmittals, isDeleted } = props;
  const [height, setHeight] = useState(0);
  const [isGridReady, setGridReady] = useState(false);
  const gridRef = useRef<AgGridReact<any>>(null);

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Impacted Submittals",
        children: [
          {
            headerName: "Title",
            headerTooltip: "TITLE",
            sort: "asc",
            width: 200,
            minWidth: 200,
            valueGetter: (param: any) => {
              if (!param?.data?.SDB?.old?.spec_no)
                return `${
                  param?.data?.SDB?.old?.submittal_sequence_id || ""
                } | ${param?.data?.SDB?.old?.title}`;
              return `${param?.data?.SDB?.old?.spec_no || ""} - ${
                param?.data?.SDB?.old?.submittal_sequence_id || ""
              } | ${param?.data?.SDB?.old?.title}`;
            },
            tooltipValueGetter: ({ value }: any) => value,
            cellRenderer: ({ value, data }: any) => {
              return (
                <Link
                  to={{
                    pathname: `submittals/${
                      data?.submittalId || data?.submittal_id
                    }`
                  }}
                  target="_blank"
                >
                  {value}
                </Link>
              );
            }
          },
          {
            headerName: "Risk Assessment",
            headerTooltip: "RISK ASSESSMENT",
            width: 250,
            cellStyle: {
              // "white-space": "normal",
              whiteSpace: "normal",
              height: "100%",
              // "line-height": "1.5"
              lineHeight: "1.5"
            },
            valueGetter: (params: any) => {
              return params.data?.SDB?.new?.risk_assessment?.text;
            },
            tooltipValueGetter: ({ value }: any) => {
              return value;
            },
            cellRenderer: (params: any) => {
              const risk = params.data?.SDB?.new?.risk_assessment;
              const riskClass =
                risk?.level === RiskLevelType.High ? "text-[#F3523A]" : "";
              return (
                <div className={`line-clamp2 text-xs ${riskClass}`}>
                  {risk?.text}
                </div>
              );
            }
          },
          {
            headerName: "Assigned to Subcontractor​",
            headerTooltip: "ASSIGNED TO SUBCONTRACTOR",
            field: "assigned",
            cellRenderer: ScheduleCellRenderer,
            cellRendererParams: { isDeleted },
            width: 150
          },
          {
            headerName: "Next Milestone​",
            headerTooltip: "NEXT MILESTONE",
            field: "next_milestone",
            cellRenderer: ScheduleCellRenderer,
            cellRendererParams: { isDeleted },
            width: 250,
            cellStyle: { whiteSpace: /* "white-space": */ "normal" }
          },
          // {
          //   headerName: "SC Submittal Prep",
          //   headerTooltip: "SC SUBMITTAL PREP",
          //   field: "submittal_prep",
          //   width: 130,
          //   valueGetter: (param: any) => {
          //     return `${
          //       param.data.SDB?.old?.submittal_prep_offset || ""
          //     }  Day(s)`;
          //   }
          // },
          // {
          //   headerName: "Submitted By Subcontractor",
          //   headerTooltip: "SUBMITTED BY SUBCONTRACTOR",
          //   field: "trade_partner_submitted_date",
          //   cellRenderer: ScheduleCellRenderer,
          //   cellRendererParams: { isDeleted },
          //   width: 130
          // },
          // {
          //   headerName: "GC Review",
          //   headerTooltip: "GC REVIEW",
          //   field: "gc_review_offset",
          //   width: 100,
          //   valueGetter: (param: any) => {
          //     return `${param.data.SDB?.old?.gc_review_offset || ""}  Day(s)`;
          //   }
          // },
          // {
          //   headerName: "Submitted for Design Review",
          //   headerTooltip: "SUBMITTED FOR DESIGN REVIEW",
          //   field: "submitted_for_external_review",
          //   cellRenderer: ScheduleCellRenderer,
          //   cellRendererParams: { isDeleted },
          //   width: 130
          // },
          // {
          //   headerName: "Design Review",
          //   headerTooltip: "DESIGN REVIEW",
          //   width: 130,
          //   field: "ext_review_offset",
          //   valueGetter: (param: any) => {
          //     return `${param.data.SDB?.old?.ext_review_offset || ""}  Day(s)`;
          //   }
          // },
          // {
          //   headerName: "Design Review Complete",
          //   headerTooltip: "DESIGN REVIEW COMPLETE",
          //   field: "external_review_complete",
          //   cellRenderer: ScheduleCellRenderer,
          //   cellRendererParams: { isDeleted },
          //   width: 130
          // },
          // {
          //   headerName: "Final GC Review",
          //   headerTooltip: "FINAL GC REVIEW",
          //   field: "final_gc_review",
          //   width: 130,
          //   valueGetter: (param: any) => {
          //     return `${
          //       param.data.SDB?.old?.final_gc_review_offset || ""
          //     }  Day(s)`;
          //   }
          // },
          {
            headerName: "Submittal Distributed",
            headerTooltip: "SUBMITTAL DISTRIBUTED",
            field: "submittal_distributed",
            cellRenderer: ScheduleCellRenderer,
            cellRendererParams: { isDeleted },
            width: 130
          },

          {
            headerName: "Final Deadline Date",
            headerTooltip: "FINAL DEADLINE DATE",
            field: "SI_FDD",
            cellRenderer: ScheduleCellRenderer,
            cellRendererParams: { isDeleted },
            width: 160
          },
          {
            headerName: "Float",
            headerTooltip: "FLOAT",
            field: "float",
            cellRenderer: ScheduleCellRenderer,
            cellRendererParams: { isDeleted },
            width: 110
          },
          {
            headerName: "Governing Task/Material",
            headerTooltip: "GOVERNING TASK/MATERIAL",
            field: "governing_activity",
            cellRenderer: GoverningItemCellRenderer,
            cellRendererParams: { isDeleted },
            width: 200
          }
        ]
      }
    ];
  }, [isDeleted]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: false,
      filter: true,
      resizable: true,
      editable: false,
      autoHeight: true,
      menuTabs: []
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const getRowHeight = () => rowHeight;

  const setRowHeight = () => {
    const materialCount = linkedSubmittals?.length ?? 0;
    const minCount = Math.min(materialCount, maxRowCount);

    const viewHeight: number = minCount * rowHeight + headerHeight;
    setHeight(viewHeight);
    return viewHeight;
  };

  return (
    <div
      style={{ width: "100%", height }}
      className="ag-theme-alpine inner-table"
    >
      <div className="uppercase tracking-widest inline-block text-[11px] font-bold text-[#888] mb-1">
        Impacted submittals
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={linkedSubmittals!}
        onGridReady={() => {
          setGridReady(true);
          gridRef.current?.api.sizeColumnsToFit();
          setRowHeight();
        }}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        headerHeight={headerHeight}
        groupHeaderHeight={0}
        getRowHeight={getRowHeight}
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
      />
    </div>
  );
}

export default SubmittalScheduleImpact;
