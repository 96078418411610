import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { BID_PACKAGE_LIST_VIEW } from "../queries";

export const usePreconstrutionData = () => {
  const { gqlClientForProject } = useContext(ProjectContext);

  const { data: bidPackageList, refetch: refetchBidPackageList } = useCIQQuery<{
    bid_package_list_view: Array<any>;
  }>(BID_PACKAGE_LIST_VIEW, {
    variables: {},
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });
  return {
    bidPackageList,
    refetchBidPackageList
  };
};
