import BidPackageHeader from "pages/preconstruction-details/components/bid-package-header";
import DesignLinkedTopSection from "./components/design-linked-top-section";

function DesignPackageDetailsTab(props: {
  featureInstanceData: any;
  actions: any;
  featureId: string;
}) {
  const { featureInstanceData, actions, featureId } = props;
  console.log("featureId ", featureId);

  return (
    <div className="space-y-4 mb-4">
      <BidPackageHeader
        actions={actions}
        bidNumber={undefined}
        headerTitle={featureInstanceData?.title}
        status={featureInstanceData?.feature_workflow_instance?.workflow_status}
      />
      <DesignLinkedTopSection
        actions={actions}
        featureInstanceData={featureInstanceData}
      />
    </div>
  );
}

export default DesignPackageDetailsTab;
