import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateUtils } from "utils/dateutils";
import { riskCellRenderer } from "components/cell-renders";
import { TLinkedSubmittal } from "../models";

export const submittalColDefs = [
  {
    headerName: "Linked Submittals",
    children: [
      {
        colId: "submittal_id",
        field: "submittal_id",
        headerName: "SUBMITTAL ID",
        headerTooltip: "SUBMITTAL ID",
        cellRenderer: "agGroupCellRenderer",
        valueGetter: ({ data, context }) => {
          if (!data) return "";
          const { projectDetails } = context;
          return projectDetails?.spec_section && data.spec_no
            ? `${data.spec_no} - ${data.submittal_id}`
            : `${data.submittal_id}`;
        },
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "specsection",
        headerName: "Spec Section",
        headerTooltip: "Spec Section",
        valueGetter: ({ data }) =>
          data?.spec_no ? `${data?.spec_no} - ${data?.spec_name}` : "",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "title",
        field: "title",
        headerName: "TITLE",
        headerTooltip: "TITLE",
        tooltipField: "title"
      },
      {
        colId: "workflow_status",
        field: "workflow_status",
        headerName: "STATUS",
        headerTooltip: "STATUS",
        tooltipField: "status"
      },
      {
        colId: "assignee",
        headerName: "BALL IN COURT",
        headerTooltip: "BALL IN COURT",
        valueGetter: ({ data }) =>
          data?.submittal_assignee
            ? `${data?.submittal_assignee.first_name} ${data?.submittal_assignee.last_name}`
            : "",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "due_date",
        headerName: "DUE DATE",
        headerTooltip: "DUE DATE",
        valueGetter: ({ data }) =>
          data?.date_block_submittals?.length
            ? DateUtils.format(data?.date_block_submittals[0]?.due_date)
            : "",
        sort: "asc",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "risk",
        headerName: "RISK",
        headerTooltip: "RISK",
        valueGetter: ({ data }) =>
          data?.date_block_submittals?.length
            ? data?.date_block_submittals[0].risk_level
            : "",
        cellRenderer: riskCellRenderer,
        tooltipValueGetter: ({ value }) => value
      }
    ]
  }
] as (ColDef<TLinkedSubmittal> | ColGroupDef<TLinkedSubmittal>)[];
