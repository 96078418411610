import NotFoundPage from "pages/not-found";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext } from "react";
import RiskReportCardIcon from "components/svg-icons/risk-report-card-icon";
import UpcomingSubmittalsCardIcon from "components/svg-icons/upcoming-submittal-card-icon";
import { useHistory, useParams } from "react-router";
import { ScheduleReportIcon } from "components/svg-icons";
import { SubscriptionContext } from "context/SubscriptionProvider";
import ReportCard from "./components/report-card";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";

function ReportsPageManu() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const { tokenContents }: TProjectContext = useContext(ProjectContext);
  const { productEntitlements } = useContext(SubscriptionContext);

  const isScheduleLookAheadReport = productEntitlements.some(
    (f) => f.enabled && f.feature === "SCHEDULE_LOOK_AHEAD_REPORT"
  );

  const cannotViewProjectReports = tokenContents
    ? isPermissionNotGrantted(
        ProjectPermissionEnum.ViewProjectReports,
        tokenContents?.role
      )
    : false;

  if (cannotViewProjectReports) {
    return (
      <NotFoundPage
        status="403"
        title="403"
        subTitle={ErrorMessages.UnauthorizedPageAccess}
      />
    );
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="w-full uppercase px-4 py-2 font-medium">Reports</div>
      <div className="flex h-full bg-white p-4">
        <div className="flex flex-wrap h-full">
          <ReportCard
            title="Risk Report"
            icon={<RiskReportCardIcon />}
            onClick={() => {
              history.push(`/project/${projectId}/reports/risk`);
            }}
            detail="Generate this report to identify the risk associated with all the workflows in your project. You can export this report to share it with other stakeholders"
          />

          <ReportCard
            title="Upcoming Procurement Deadlines"
            icon={<UpcomingSubmittalsCardIcon />}
            onClick={() => {
              history.push(`/project/${projectId}/reports/upcoming`);
            }}
            detail="Review upcoming submittals and materials, their due dates, and their impact on subsequent workflows. Engage with relevant subcontractors regarding urgent submittals and materials to ensure timely follow-up."
          />

          {isScheduleLookAheadReport && (
            <ReportCard
              title="Schedule Lookahead Report"
              icon={<ScheduleReportIcon />}
              onClick={() => {
                history.push(`/project/${projectId}/reports/activities`);
              }}
              detail="Generate this report to view scheduled activities within a specific date range, as well as the procurement readiness for the relevant activities​."
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportsPageManu;
