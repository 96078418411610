import { gql } from "@apollo/client";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";
import {
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET
} from "entity-app/graphQL/ciq-feature-mutations";
import { GET_FEATURE_INSTANCE_PLANNING_DATA } from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData } from "entity-app/services";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useState } from "react";

type UUID = string;
type ISO8601Date = string;

interface FeatureInstance {
  id: UUID;
  title: string;
  feature_type_id: number;
}

export interface WorkflowInstanceOffset {
  id: UUID;
  name: string;
  duration: number;
}

interface WorkflowInstanceMilestone {
  id: UUID;
  name: string;
  planned_date: ISO8601Date;
}

interface FeatureWorkflowInstance {
  id: UUID;
  manual_entry_date: ISO8601Date;
}

interface TargetFeatureInstance extends FeatureInstance {
  project_id: UUID;
  feature_workflow_instance: FeatureWorkflowInstance;
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  workflow_instance_offsets: WorkflowInstanceOffset[];
}

interface Link {
  id: UUID;
  target_gantt_task: null;
  target_feature_instance: TargetFeatureInstance;
}

interface NestedFeatureInstance extends FeatureInstance {
  workflow_instance_offsets: WorkflowInstanceOffset[];
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  links_as_source: Link[];
}

export interface RootFeatureInstance extends FeatureInstance {
  links_as_source: {
    target_feature_instance: NestedFeatureInstance;
  }[];
}

interface PlanningLink extends Link {
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  workflow_instance_offsets: WorkflowInstanceOffset[];
  links_as_source: {
    target_feature_instance?: NestedFeatureInstance;
    target_gantt_task?: any;
  }[];
  feature_workflow_instance: FeatureWorkflowInstance;
}

export type PlansForFeatures = {
  [K in keyof typeof FeatureTypes]?: PlanningLink;
};

function extractTargetFeatureInstances(data: any): any[] {
  const instances: any[] = [];

  function traverse(obj: any) {
    if (typeof obj !== "object" || obj === null) return;

    if (obj.target_feature_instance) {
      instances.push(obj.target_feature_instance);
    }

    if (Array.isArray(obj)) {
      obj.forEach(traverse);
    } else {
      Object.values(obj).forEach(traverse);
    }
  }

  traverse(data);
  return instances;
}

export function useBidPackagePlanning(params: {
  featureId: string;
  token: string;
}) {
  const { featureId, token } = params;

  const [featureLinks, setFeatureLinks] = useState<PlansForFeatures | null>(
    null
  );
  const [loadingLinks, setLoadingLinks] = useState(false);

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationUpdateFeatureWorkflowInstanceOffset] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstance] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const getPlanningData = useCallback(async () => {
    if (!token || !featureId) {
      return;
    }
    setLoadingLinks(true);
    let plansForFeatures: any = null;
    const PlanningDataResponse = await getGQLQueryData(
      token,
      GET_FEATURE_INSTANCE_PLANNING_DATA,
      {
        featureId
      }
    );

    if (PlanningDataResponse.success) {
      const allLinks = extractTargetFeatureInstances(
        structuredClone(PlanningDataResponse.data.data.feature_instance_by_pk)
      );

      allLinks.forEach((link: any) => {
        plansForFeatures = plansForFeatures || {};
        plansForFeatures[FeatureTypes[link.feature_type_id]] = link;
      });
    }
    setFeatureLinks(plansForFeatures);
    setLoadingLinks(false);
  }, [featureId, token]);

  useEffect(() => {
    getPlanningData();
  }, [getPlanningData]);

  const updateWorkflowInstanceOffset = useCallback(
    async (updateParams: { offsetId: string; value: number }) => {
      const { offsetId, value } = updateParams;
      const updateResponse = await mutationUpdateFeatureWorkflowInstanceOffset({
        variables: {
          id: offsetId,
          set: {
            duration: value
          }
        }
      });
      getPlanningData();
      return updateResponse;
    },
    [getPlanningData, mutationUpdateFeatureWorkflowInstanceOffset]
  );

  const updateWorkflowInstance = useCallback(
    async (updateParams: { id: string; set: any }) => {
      const updateResponse = await mutationUpdateFeatureWorkflowInstance({
        variables: updateParams
      });
      getPlanningData();
      return updateResponse;
    },
    [getPlanningData, mutationUpdateFeatureWorkflowInstance]
  );

  return {
    featureLinks,
    loadingLinks,
    updateWorkflowInstanceOffset,
    updateWorkflowInstance,
    refetch: getPlanningData
  };
}
