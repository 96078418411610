import { EditOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import "./inline-edit-title.scss";

const { Paragraph } = Typography;

function InlineEditTitle(props: {
  isEditable: boolean;
  title: string;
  updateTitle: any;
}) {
  const { isEditable, title, updateTitle } = props;
  return (
    <Paragraph
      className="max-w-xs !my-0"
      editable={
        isEditable
          ? {
              icon: <EditOutlined className="ml-1 text-[#3B3B3BCC]" />,
              tooltip: null,
              onChange: updateTitle,
              maxLength: 1000
            }
          : false
      }
    >
      {title}
    </Paragraph>
  );
}

export default InlineEditTitle;
