import { useMemo } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useParams } from "react-router";
import { twoRowCellRenderer } from "../../../entity-app/shared-components/log-render-components/cell-renders";
import {
  coldefNavigation,
  coldefSingleField,
  coldefUser
} from "../../../entity-app/shared-components/log-render-components/col-defs";

export const useDesignPackageAgGrid = (props: { featureKey: string }) => {
  const { featureKey } = props;
  const { projectId } = useParams() as any;

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    resizable: true,
    menuTabs: ["filterMenuTab"],
    minWidth: 100,
    flex: 1
  };

  const columnDefs = useMemo(
    () =>
      [
        coldefNavigation(
          {
            field: "auto_inc_value",
            headerName: "ID",
            pinned: "left",
            lockPosition: "left",
            checkboxSelection: true,
            width: 100,
            sort: "asc"
          },
          projectId,
          featureKey
        ),
        coldefSingleField({
          field: "number",
          headerName: "NUMBER",
          pinned: "left",
          lockPosition: "left",
          width: 120
        }),
        coldefSingleField({
          field: "title",
          headerName: "DESIGN PACKAGE TITLE",
          width: 180,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: twoRowCellRenderer,
          cellRendererParams: {
            featureKey,
            projectId
          }
        }),
        coldefSingleField({ field: "workflow_status", headerName: "STATUS" }),
        coldefSingleField({
          field: "design_firm_name",
          headerName: "RESPONPOSIBLE DESIGN FIRM"
        }),
        coldefUser({
          def: { headerName: "RESPONPOSIBLE DESIGNER" },
          user: {
            id: "responsible_designer_id",
            firstName: "responsible_designer_first_name",
            lastName: "responsible_designer_last_name"
          }
        }),
        coldefUser({
          def: { headerName: "GC REPRESENTATIVE" },
          user: {
            id: "gc_representative_id",
            firstName: "gc_representative_first_name",
            lastName: "gc_representative_last_name"
          }
        })
      ] as (ColDef<any> | ColGroupDef<any>)[],
    [projectId, featureKey]
  );
  return {
    columnDefs,
    defaultColDef
  };
};
