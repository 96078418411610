import EntityDateblock from "entity-app/shared-components/entity-dateblock";

function HeadingDateBlock() {
  return (
    <div className="flex w-full h-14 justify-between items-center px-3 py-2 text-[#3B3B3BCC]">
      <div className="uppercase text-sm font-semibold">
        DESIGN PACKAGE SCHEDULE
      </div>

      <div>*Governing Date</div>
    </div>
  );
}

function DesignLinkedTopSection(props: {
  featureInstanceData: any;
  actions: any;
}) {
  const { featureInstanceData, actions } = props;
  return (
    <div className="w-full h-[470px] border border-black border-solid flex">
      <div className="grow overflow-auto border-0 border-r border-solid border-[#3b3b3b80]">
        <HeadingDateBlock />
        <div className="overflow-auto px-3 flex items-center justify-center">
          <EntityDateblock
            actions={actions}
            workflowInstanceMilestones={
              featureInstanceData?.workflow_instance_milestones
            }
            featureWorkflowInstance={
              featureInstanceData?.feature_workflow_instance
            }
            showFloat={featureInstanceData?.feature?.show_float}
          />
        </div>
      </div>
      <div className="w-[280px] shrink-0 overflow-y-auto p-4">
        LINKED BID PACKAGES
      </div>
    </div>
  );
}
export default DesignLinkedTopSection;
