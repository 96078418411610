import { BASE_URL, REPORT_EXPORT_URL } from "services/endpoints";
import moment from "moment";
import { message } from "antd";
import {
  DATE_FORMAT_MMDDYYYY,
  DATE_FORMAT_MMDDYYYY_HHMM_A_Z
} from "utils/dateutils";
import { getTheAppUrl, renderTemplate } from "utils/utils";
import { ProjectSettingType } from "context/ProjectProvider";
import sanitizeFileName from "sanitize-filename";
// eslint-disable-next-line import/no-webpack-loader-syntax
import footerHtmlTemplate from "raw-loader!./templates/footer.html";
// eslint-disable-next-line import/no-webpack-loader-syntax
import headerHtmlTemplate from "raw-loader!./templates/header.html";
import { ErrorMessages } from "../../constants";
import { client } from "../axios";
import { getAccessToken, getCurrentSubscriptionId } from "../auth";
import { IJSReportData } from "./jsreport-request-data.interface";

/**
 * Method to sanitize the download file name and give the final file name to save
 * @param projectDetails
 * @returns
 */
const getTheFileName = (projectName?: any) => {
  const cleanedProjectName = sanitizeFileName(
    projectName || "project-name-not-found"
  );
  // Project Timezone is set as default timezone for moment across the application in navbar module.
  const today = moment().format(DATE_FORMAT_MMDDYYYY);
  const fileName = `Risk Report of ${cleanedProjectName}_${today}`; //  Risk Report of (Project Name)_(Date of Export in MM/DD/YYYY)

  return fileName;
};

const getTemplateData = (templateData: any) => {
  return {
    "#{reportTitle}": templateData.reportTitle || "",
    "#{createdBy}": templateData.createdBy || "",
    "#{createdOn}": templateData.createdOn,
    "#{projectName}": templateData.projectName || ""
  };
};

const getTheHeaderTemplate = (templateData: any) => {
  return renderTemplate(headerHtmlTemplate, getTemplateData(templateData));
};

const getTheFooterTemplate = (templateData: any) => {
  return renderTemplate(footerHtmlTemplate, getTemplateData(templateData));
};

const getJSReportServerConfig = (templateData: any): IJSReportData => {
  const { reportTemplateUrl } = templateData;

  let urlWithToken = renderTemplate(reportTemplateUrl, {
    "#{accessToken}": getAccessToken() || "",
    "#{subscriptionId}": getCurrentSubscriptionId() || ""
  });

  urlWithToken = getTheAppUrl() + urlWithToken;

  const data: IJSReportData = {
    template: {
      content: " ",
      engine: "none",
      recipe: "chrome-pdf",
      chrome: {
        format: "A4",
        landscape: true,
        waitForJS: true,
        waitForNetworkIdle: true,
        printBackground: false,
        numberOfWorkers: 3,

        marginLeft: "",
        marginTop: "2.4cm",
        marginBottom: "1.2cm",
        margin: {
          top: "2.4cm",
          bottom: "1.2cm",
          left: ""
        },

        displayHeaderFooter: true,
        headerTemplate: getTheHeaderTemplate(templateData),
        footerTemplate: getTheFooterTemplate(templateData),

        url: urlWithToken
      }
    }
  };

  return data;
};

/**
 * Method to download the report as pdf format
 * @param reportTemplateUrl
 * @param projectDetails
 * @returns
 */
export const DownloadReport = async (
  reportTemplateUrl: string,
  reportTitle: string,
  currentUser: any,
  projectDetails: ProjectSettingType | undefined
) => {
  try {
    const templateData: any = {
      reportTemplateUrl,
      reportTitle,
      createdBy: `${currentUser.first_name || currentUser["first-name"]} ${
        currentUser.last_name || currentUser["last-name"]
      }`,
      createdOn: moment().format(DATE_FORMAT_MMDDYYYY_HHMM_A_Z),
      projectName: projectDetails?.name || ""
    };

    const requestData: any = getJSReportServerConfig(templateData);

    // const reportUrl = "http://localhost:4000/reports"; // for local development
    const reportUrl = `${BASE_URL}${REPORT_EXPORT_URL}`;

    const downloadResponse = await client.post(reportUrl, requestData, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const href = URL.createObjectURL(downloadResponse.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `${getTheFileName(templateData.projectName)}.pdf`
    ); // give custom file name
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    message.success(ErrorMessages.reportExport.submittalSuccessMessage);
    return downloadResponse;
  } catch (error) {
    console.log("There is an error in report download...");
    console.log(error);

    message.error(ErrorMessages.reportExport.errorMessage);
    return { error };
  }
};
