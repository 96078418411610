import { GET_FEATURE_TO_FEATURE_LINKS } from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData } from "entity-app/services";
import { useCallback, useEffect, useState } from "react";

function useFeatureToFeatureLinks(params: {
  token: string;
  featureInstanceId: string;
  featureTypeId: number;
}) {
  const { token, featureInstanceId, featureTypeId } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [featureLinks, setfeatureLinks] = useState([]);

  const getFeatureToFeatureLinks = useCallback(async () => {
    if (!token || !featureInstanceId || !featureTypeId) {
      return;
    }
    setIsLoading(true);
    const response = await getGQLQueryData(
      token,
      GET_FEATURE_TO_FEATURE_LINKS,
      {
        featureInstanceId,
        featureTypeId
      }
    );
    setIsLoading(false);

    if (response.success) {
      setfeatureLinks(response.data.data.feature_linking);
    }
  }, [featureInstanceId, featureTypeId, token]);

  useEffect(() => {
    getFeatureToFeatureLinks();
  }, [getFeatureToFeatureLinks]);

  return { isLoading, featureLinks };
}

export default useFeatureToFeatureLinks;
