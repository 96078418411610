import { useMemo } from "react";
import moment from "moment";
import { LinkTypes } from "entity-app/constants";
import { ActivityItem as TActivityItem } from "entity-app/hooks/feature-activity-linking";
import ActivityItem from "./activity-item";

function LinkedActivitiesList({
  linkedActivities,
  onUnlink
}: {
  linkedActivities: TActivityItem[];
  onUnlink: (activity: TActivityItem) => void;
}) {
  const sortedActivities = useMemo(() => {
    return linkedActivities?.sort((a: TActivityItem, b: TActivityItem) => {
      // if activity is newly linked, it should be at the top
      if (a.newlyLinked) {
        return -1;
      }
      if (b.newlyLinked) {
        return 1;
      }
      const aDateString =
        a.link_type === LinkTypes.finish_to_start ? a.start_date : a.end_date;
      const bDateString =
        b.link_type === LinkTypes.finish_to_start ? b.start_date : b.end_date;

      return moment(aDateString).diff(moment(bDateString));
    });
  }, [linkedActivities]);

  return (
    <div className="flex flex-col gap-y-2">
      {sortedActivities?.map((activity: TActivityItem) => {
        return (
          <ActivityItem
            key={activity.id}
            activity={activity}
            onUnlink={onUnlink}
          />
        );
      })}
    </div>
  );
}

export default LinkedActivitiesList;
