import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateUtils } from "utils/dateutils";
import { riskCellRenderer } from "components/cell-renders";
import { TLinkedMaterial } from "../models";

export const materialColDef = [
  {
    headerName: "Linked Materials",
    children: [
      {
        colId: "material_id",
        field: "material_id",
        tooltipField: "material_id",
        headerName: "MATERIAL ID",
        headerTooltip: "MATERIAL ID",
        cellRenderer: "agGroupCellRenderer"
      },
      {
        colId: "specsection",
        headerName: "Spec Section",
        headerTooltip: "Spec Section",
        valueGetter: ({ data }) =>
          data?.spec_section_no && data?.spec_section_name
            ? `${data?.spec_section_no} - ${data?.spec_section_name}`
            : "",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "name",
        field: "name",
        headerName: "MATERIAL NAME",
        headerTooltip: "MATERIAL NAME",
        tooltipField: "name"
      },
      {
        colId: "status",
        field: "status",
        headerName: "MATERIAL STATUS",
        headerTooltip: "MATERIAL STATUS",
        tooltipField: "status"
      },
      {
        colId: "gc_representative",
        headerName: "GC REPRESENTATIVE",
        headerTooltip: "GC REPRESENTATIVE",
        valueGetter: ({ data }) =>
          data?.gc_representative_user
            ? `${data?.gc_representative_user.first_name} ${data?.gc_representative_user.last_name}`
            : "",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "due_date",
        headerName: "DUE DATE",
        headerTooltip: "DUE DATE",
        valueGetter: ({ data }) =>
          data?.date_block_materials?.length
            ? DateUtils.format(data?.date_block_materials[0]?.due_date)
            : "",
        sort: "asc",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "risk",
        headerName: "RISK",
        headerTooltip: "RISK",
        valueGetter: ({ data }) =>
          data?.date_block_materials?.length
            ? data?.date_block_materials[0].risk_level
            : "",
        cellRenderer: riskCellRenderer,
        tooltipValueGetter: ({ value }) => value
      }
    ]
  }
] as (ColDef<TLinkedMaterial> | ColGroupDef<TLinkedMaterial>)[];
