export const ORGANIZATION_LIST = `AllOrganizations {
  organization(order_by: {name: asc}) {
    domain
    id
    name
    created_at
    created_by_user {
      first_name
      last_name
      email
      id
    }
    organization_subscriptions_aggregate {
      aggregate {
        count
      }
    }
  }
}`;

export const ORGANIZATION_DETAIL = `OrganizationPK($id: uuid!) {
  organization_by_pk(id: $id) {
    id
    name
    organization_subscriptions {
      subscription {
        name
        created_at
        created_by_user {
          first_name
          last_name
          email
          id
        }
        id
        subscription_users(where: {created_by_user: {env_admin: {_eq: true}}}) {
          status_id
          user {
            first_name
            last_name
            email
            id
          }
        }
      }
    }
  }
}`;

export const SUBSCRIPTION_PK_DETAILS = `SubcriptionPK($subscription_id: uuid!) {
  subscription_by_pk(id: $subscription_id) {
    id
    name
    organization_subscriptions {
      organization {
        id
        name
      }
    }
    subscription_users {
      user_id
      user {
        email
        first_name
        id
        last_name
      }
    }
    projects_owned(order_by: {name: asc}) {
      id
      name
    }    
  }
}`;

export const USERS_OF_SUBSCRIPTION = `subscriptionUsers($subscription_id: uuid !) {
  subscription_users(where: {subscription_id: {_eq: $subscription_id}}) {
    user_id
    status_id
    subscription_permission_id
    user {
      email
      first_name
      last_name
      id
    }
    created_at
    created_by_user {
      email
    }
  }
}`;

export const PROJECTS_OF_SUBSCRIPTION = `subscriptionProjects($subscription_id: uuid!) {
  project(where: {owner_subscription: {id: {_eq: $subscription_id}}}) {
     id
      name
      number
      mode
      status_id
      spec_section
      details
      start_date
      end_date
      address
      created_by_user {
        email
      }
  }
}
`;

export const USER_ACTIVITIES_EVENTS = `
 getAllEvents($fromDate: timestamptz!, $toDate: timestamptz!) {
  audit_subscription_events(where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}, distinct_on: event_log_id) {
    event_log {
      event {
        name
      }
    }
    created_by_user {
      email
      first_name
      last_name
    }
    subscription {
      name
      organization_subscriptions {
        organization {
          name
        }
      }
    }
    created_at
  }
  audit_project_events(where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}, distinct_on: event_log_id) {
    event_log {
      event {
        name
      }
    }
    created_by_user {
      email
      first_name
      last_name
    }
    project {
      name
      owner_subscription {
        name
        organization_subscriptions {
          organization {
            name
          }
        }
      }
    }
    created_at
  }
  audit_project_feature_events(where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}, distinct_on: event_log_id) {
    event_log {
      event {
        name
      }
    }
    user_created {
      email
      first_name
      last_name
    }
    project {
      name
      owner_subscription {
        name
        organization_subscriptions {
          organization {
            name
          }
        }
      }
    }
    created_at
  }
}
`;
