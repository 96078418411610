import { Button, DatePicker, Divider, Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useMemo, useState } from "react";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import { Moment } from "moment";

const { RangePicker } = DatePicker;

export type TDateRangeActivity = {
  startDate: Moment | null;
  endDate: Moment | null;
};

function ActivitiesFilterForm({
  open,
  onFinish,
  onCancel,
  onBack,
  closable
}: {
  open: boolean;
  closable: boolean;
  onFinish: (dates: TDateRangeActivity) => void;
  onBack: (e: any) => void;
  onCancel: any;
}) {
  const [form] = useForm();
  const filterValue = Form.useWatch("filter", form);
  const todayDate = DateUtils.dateTimeObj().startOf("day");
  const dateAfter5WeekDate = DateUtils.dateTimeObj()
    .startOf("day")
    .add(35, "days");
  const dateAfter6WeekDate = DateUtils.dateTimeObj()
    .startOf("day")
    .add(42, "days");

  const [dateRange, setDateRange] = useState<any>();

  const filterOptions = useMemo(() => {
    return [
      {
        value: 1,
        label: "5-week Lookahead​"
      },
      {
        value: 2,
        label: "6-week Lookahead"
      },
      { value: 3, label: "Custom Date Range" }
    ];
  }, []);

  const resetFormState = () => {
    setDateRange(undefined);
    form.resetFields();
  };

  return (
    <Modal
      className="custom-drawer"
      title="SCHEDULE LOOKAHEAD REPORT"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={open}
      closable={closable}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      keyboard={false}
    >
      <div className="px-3 -mt-4">
        <Form
          form={form}
          layout="vertical"
          className=""
          onFinish={() => {
            onFinish({
              startDate: dateRange[0],
              endDate: dateRange[1]
            });
          }}
        >
          <div>
            <div className="relative">
              <Form.Item name="filter" label="Activity Date Range" required>
                <Select
                  options={filterOptions}
                  onSelect={(v: any) => {
                    switch (v) {
                      case 1:
                        setDateRange([todayDate, dateAfter5WeekDate]);
                        break;
                      case 2:
                        setDateRange([todayDate, dateAfter6WeekDate]);
                        break;
                      case 3:
                        setDateRange(undefined);
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Form.Item>

              {filterValue && (
                <RangePicker
                  className="w-full"
                  format={DATE_FORMAT_MMDDYYYY}
                  disabled={filterValue !== 3}
                  disabledDate={(date) => date.diff(todayDate) < 0}
                  value={dateRange}
                  onChange={(dates) => {
                    form.setFieldValue("filter", 3);
                    setDateRange(dates);
                  }}
                />
              )}
            </div>
          </div>

          <Divider className="mx-0 mt-1 mb-4" />
          <div className="flex w-full justify-end space-x-4">
            {!closable && (
              <Button htmlType="reset" className="px-5" onClick={onBack}>
                Back
              </Button>
            )}
            {closable && (
              <Button htmlType="reset" className="px-5" onClick={onCancel}>
                Close
              </Button>
            )}
            <Button htmlType="reset" className="px-5" onClick={resetFormState}>
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="px-5"
              disabled={!dateRange}
            >
              Run Report
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
export default ActivitiesFilterForm;
