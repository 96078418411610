import {
  GET_LINKED_ACTIVITIES,
  GET_SCHEDULE_ACTIVITIES,
  GET_SCHEDULE_LIST
} from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData, modifyFeatureLinks } from "entity-app/services";
import { ScheduleTaskType } from "pages/submittal-schedule-linking/models";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { makeScheduleAsActive } from "services/schedule-versions-services";

export type Schedule = {
  id: number;
  schedule_name: string;
  is_active: boolean;
  schedule_date: string;
  import_log?: {
    resolved_values_s3_prefix: string;
    id: string;
  };
  import_log_id: string;
};

export type LinkAsSourceForActivity = {
  id: string;
  link_type: number;
  created_at: string;
  updated_at: string;
  target_gantt_task: {
    id: string;
    start_date: string;
    end_date: string;
    source_task_id: string;
    type: number;
    text: string;
  };
};

export type ActivityItem = {
  id: string;
  link_id: string;
  link_type: number;
  source_task_id: string;
  start_date: string;
  end_date: string;
  link_created_at: string;
  newlyLinked?: boolean;
};

export function useFeatureActivityLinking(params: {
  featureId: string | undefined;
  token: string;
  selectedSchedule?: Schedule | null;
  skip: boolean;
}) {
  const { featureId, token, selectedSchedule, skip } = params;
  const [scheduleList, setScheduleList] = useState<Schedule[] | null>(null);
  const [activitiesTree, setActivitiesTree] = useState<any>(null);
  const [linkedActivities, setLinkedActivities] = useState<ActivityItem[]>();

  const [isLoadingActivitiesTree, setIsLoadingActivitiesTree] = useState(false);
  const [isLoadingScheduleList, setIsLoadingScheduleList] = useState(false);
  const [isLoadingLinkedActivities, setIsLoadingLinkedActivities] =
    useState(false);
  const [isUpdatingFeatureLinks, setIsUpdatingFeatureLinks] = useState(false);

  const scheduleIdForLoadedActivitiesTree = useRef<number | null>(null);

  const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    abortController.current = new AbortController();
    return () => {
      abortController.current?.abort();
    };
  }, []);

  const getScheduleList = useCallback(async () => {
    if (!token) {
      return;
    }
    setIsLoadingScheduleList(true);
    const scheduleListResponse = await getGQLQueryData(
      token,
      GET_SCHEDULE_LIST
    );

    if (scheduleListResponse.success) {
      setScheduleList(
        scheduleListResponse.response.data.data.schedule_import_details
      );
    } else {
      console.error("Error fetching schedule list", scheduleListResponse);
    }
    setIsLoadingScheduleList(false);
  }, [token]);

  const getLinkedActivities = useCallback(async () => {
    if (!token || !featureId) {
      return;
    }
    setIsLoadingLinkedActivities(true);
    const linkedActivitiesResponse = await getGQLQueryData(
      token,
      GET_LINKED_ACTIVITIES,
      { featureInstanceId: featureId }
    );
    // console.log("linkedActivitiesResponse", linkedActivitiesResponse);
    if (linkedActivitiesResponse.success) {
      const finalActivities =
        linkedActivitiesResponse.response.data.data.feature_instance[0].links_as_source
          .filter(
            (activity: LinkAsSourceForActivity) => activity.target_gantt_task
          )
          .map((activity: LinkAsSourceForActivity) => {
            return {
              link_id: activity.id,
              link_type: activity.link_type,
              link_created_at: activity.created_at,
              ...activity.target_gantt_task
            };
          });
      setLinkedActivities(finalActivities);
    } else {
      console.error(
        "Error fetching linked activities",
        linkedActivitiesResponse
      );
    }
    setIsLoadingLinkedActivities(false);
  }, [token, featureId]);

  const getActivitiesForSchedule = useCallback(
    async (forSchedule: Schedule) => {
      setIsLoadingActivitiesTree(true);
      const activitesResponse = await getGQLQueryData(
        token,
        GET_SCHEDULE_ACTIVITIES
      );

      // console.log("activitesResponse", activitesResponse);

      const filteredTasks = activitesResponse?.data?.data?.gantt_tasks.filter(
        (ts: ScheduleTaskType) => ts.type !== 3 && ts.type !== 4
      );

      const addParent = (t: ScheduleTaskType, org: Array<any>) => {
        if (t.parent) {
          const parent = filteredTasks?.find(
            (ts: ScheduleTaskType) => ts.id === t.parent
          );
          if (parent) addParent(parent, org);
        }
        org.push(t.id);
      };
      const tree = filteredTasks?.map((t: ScheduleTaskType) => {
        const org: any[] = [];
        if (t) addParent(t, org);
        return { org, ...t };
      });
      setActivitiesTree(tree);
      setIsLoadingActivitiesTree(false);
      scheduleIdForLoadedActivitiesTree.current = forSchedule.id;
    },
    [token]
  );

  const activateScheduleAndGetActivities = useCallback(
    async (payload: { scheduleId: string; token: string }) => {
      setIsLoadingActivitiesTree(true);
      const makeScheduleAsActiveResponse: any = await makeScheduleAsActive(
        payload.scheduleId,
        payload.token,
        abortController.current?.signal
      );

      if (!makeScheduleAsActiveResponse.error) {
        getActivitiesForSchedule(selectedSchedule!);
        setIsLoadingActivitiesTree(false);
      }
      return makeScheduleAsActiveResponse;
    },
    [getActivitiesForSchedule, selectedSchedule]
  );

  const activeSchedule = useMemo(() => {
    // return null;
    return scheduleList?.find((schedule) => schedule.is_active) ?? null;
  }, [scheduleList]);

  const updateFeatureLinks = useCallback(
    async (payload: any) => {
      setIsUpdatingFeatureLinks(true);
      const response = await modifyFeatureLinks(token, payload);
      setIsUpdatingFeatureLinks(false);
      return response;
    },
    [token]
  );

  useEffect(() => {
    if (token && selectedSchedule && selectedSchedule.import_log) {
      if (scheduleIdForLoadedActivitiesTree.current !== selectedSchedule.id) {
        setActivitiesTree(null);
      }

      if (!selectedSchedule.is_active) {
        activateScheduleAndGetActivities({
          scheduleId: selectedSchedule.import_log_id,
          token
        });
      }

      if (selectedSchedule.is_active) {
        getActivitiesForSchedule(selectedSchedule);
      }
    }
  }, [
    activateScheduleAndGetActivities,
    getActivitiesForSchedule,
    selectedSchedule,
    token
  ]);

  useEffect(() => {
    if (!skip) {
      getScheduleList();
      getLinkedActivities();
    }
  }, [getScheduleList, getLinkedActivities, skip]);

  const resetStates = () => {
    setActivitiesTree(null);
    setLinkedActivities([]);
    setScheduleList(null);
    setIsLoadingActivitiesTree(false);
    setIsLoadingScheduleList(false);
    setIsLoadingLinkedActivities(false);
    setIsUpdatingFeatureLinks(false);
  };

  return {
    scheduleList,
    activitiesTree,
    activeSchedule,
    isLoadingActivitiesTree,
    linkedActivities,
    isLoadingLinkedActivities,
    isLoadingScheduleList,
    resetStates,
    updateFeatureLinks,
    isUpdatingFeatureLinks
  };
}
