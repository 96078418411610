enum FeatureTypes {
  SUBMITTALS = 1,
  MATERIALS = 2,
  BUYOUT = 3,
  BID_PACKAGE = 4,
  DESIGN_PACKAGE = 5,
  PLANNING_ONLY_SUBMITTAL = 6,
  PLANNING_ONLY_MATERIAL = 7,
  PLANNING_ONLY_ACTIVITY = 8
}

enum LinkTypes {
  finish_to_start = 0,
  start_to_start = 1,
  finish_to_finish = 2,
  start_to_finish = 3
}

export { FeatureTypes, LinkTypes };
