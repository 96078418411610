import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal
} from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import SelectFeatureSpecSections from "components/feature-spec-section-dropdown/feature-spec-sections";
import CreateSpecSectionModal from "components/submittal-details/create-spec-section";
import { ErrorMessages } from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import { MUTATION_CREATE_FEATURE_INSTANCE } from "entity-app/graphQL/ciq-feature-mutations";
import { useContext, useRef, useState } from "react";
import { restrictInputToNumbers } from "utils/inpututils";

function CreateBidPackageComponent(props: {
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelTitle: string;
  onBidPackageCreated: any;
}) {
  const [form] = Form.useForm();
  const { setDrawerOpen, showDrawerOpen, modelTitle, onBidPackageCreated } =
    props;
  const [isLoading, setLoading] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);
  const createSpecRef = useRef<any>(null);

  const [createBidPackage] = useMutation<any>(
    gql(MUTATION_CREATE_FEATURE_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const onFinish = async (values: any) => {
    const variables = {
      feature_type: "BID_PACKAGE",
      feature_instances: [
        {
          number: values?.number,
          title: values?.title,
          spec_sections: selectedIds,
          wave: values?.wave,
          description: values?.description,
          estimated_value: values?.estimated_value,
          no_of_bidders: values?.no_of_bidders
        }
      ]
    };

    setLoading(true);

    const creatResponse: any = await createBidPackage({
      variables
    });

    setLoading(false);

    if (creatResponse?.data?.insert_feature_instances_multi?.ids?.length) {
      onBidPackageCreated(
        values?.title,
        creatResponse?.data?.insert_feature_instances_multi?.ids[0]
      );
    } else {
      message.error("Unable to create bid package.");
    }
  };

  return (
    <Modal
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 96px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setSelectedIds([]);
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div>
        <Form
          preserve={false}
          form={form}
          scrollToFirstError
          layout="vertical"
          onFinish={onFinish}
        >
          <FormItem
            name="number"
            label="Bid Package Number"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.bidPackgeNumber
              }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="title"
            label="Bid Package Title"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.bidPackgeTitle
              }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem name="bid_scope" label="Bid Scope">
            <SelectFeatureSpecSections
              onSelectAction={(value: any) => {
                setSelectedIds((prev) => [...prev, value]);
              }}
              onDeselectAction={(value: any) => {
                setSelectedIds((prev) => prev.filter((id) => id !== value));
              }}
              onCreateSpecSectionClick={(showSpecSectionView: boolean) => {
                setShowCreateSpecSectionModal(showSpecSectionView);
                setTimeout(() => {
                  if (createSpecRef.current)
                    createSpecRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "end"
                    });
                }, 100);
              }}
            />
          </FormItem>
          <FormItem name="description" label="Description ">
            <TextArea />
          </FormItem>
          <FormItem name="wave" label="Wave">
            <InputNumber
              className="w-full"
              type="number"
              onKeyDown={restrictInputToNumbers}
            />
          </FormItem>
          <FormItem name="estimated_value" label="Estimated Value">
            <InputNumber
              className="w-full"
              addonBefore="$"
              min={0}
              onKeyDown={restrictInputToNumbers}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </FormItem>
          <FormItem name="no_of_bidders" label="Number of Bidders">
            <InputNumber
              className="w-full"
              type="no_of_bidders"
              onKeyDown={restrictInputToNumbers}
            />
          </FormItem>
          <div className="flex justify-end space-x-2 pt-4">
            <Button
              disabled={isLoading}
              onClick={() => {
                setSelectedIds([]);
                setDrawerOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              type="primary"
            >
              Create Bid Package
            </Button>
          </div>
        </Form>
        {showCreateSpecSectionModal && (
          <div ref={createSpecRef} className="pb-3">
            <Divider style={{ margin: "15px 0" }} />
            <div>
              <CreateSpecSectionModal
                isModalOpen={showCreateSpecSectionModal}
                onDoneCb={(newSpecSectionId: string) => {
                  if (newSpecSectionId) {
                    setShowCreateSpecSectionModal(false);
                  } else {
                    setShowCreateSpecSectionModal(false);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CreateBidPackageComponent;
