import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateUtils } from "utils/dateutils";
import { linkCellRenderer } from "./cell-renders";

export const coldefDateField = (def: ColDef<any>) => {
  const { field, headerName } = def;
  if (!field) return {} as ColDef<any>;
  return {
    colId: field,
    field,
    headerName,
    headerTooltip: headerName,
    tooltipField: field,
    valueGetter: ({ data }) => {
      return data[field] ? DateUtils.format(data[field]) : "";
    },
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefSingleField = (def: ColDef<any>) => {
  const { field, headerName } = def;
  return {
    colId: field,
    field,
    headerName,
    headerTooltip: headerName,
    tooltipField: field,
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefNavigation = (
  def: ColDef<any>,
  projectId: string,
  featureKey: string
) => {
  return {
    ...coldefSingleField(def),
    cellRenderer: linkCellRenderer,
    cellRendererParams: {
      projectId,
      featureKey
    }
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefUser = ({
  user,
  def
}: {
  def: ColDef<any>;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
}) => {
  const { headerName } = def;
  return {
    colId: user.id,
    headerName,
    headerTooltip: headerName,
    valueGetter: ({ data }) => {
      if (!data[user.id]) return "";
      return `${data[user.firstName]} ${data[user.lastName]}`;
    },
    tooltipValueGetter: ({ value }) => value,
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};
