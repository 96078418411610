import CiqLogPageHeader from "components/ciq-log-page-header";
import CiqAgGrid from "components/ciq-ag-grid";
import { TCiqAgGrid, TCiqLogHeader } from "./model";

type Props = {
  headerInfo: TCiqLogHeader;
  gridInfo: TCiqAgGrid;
};

function CiqGridViewWrapper({ gridInfo, headerInfo }: Props) {
  // import and call from services to pass data and create model instances.
  // import and use model instances here.
  // import ui-configs here and build the generic page.

  return (
    <div className="flex flex-col h-full w-full">
      <CiqLogPageHeader {...headerInfo} />
      <CiqAgGrid {...gridInfo} />
    </div>
  );
}

export default CiqGridViewWrapper;
