import { message } from "antd";
import ErrorBoundary from "components/error-boundary";
import FeatureActivityLinkingDialog from "components/feature-activity-linking-dialog";
import ActivitiesTable from "components/feature-activity-linking-dialog/activities-table";
import ActivityLinksDialogHeader from "components/feature-activity-linking-dialog/activity-links-dialog-header";
import LinkedActivitiesList from "components/feature-activity-linking-dialog/linked-activities-list";
import { ProjectContext } from "context/ProjectProvider";
import {
  ActivityItem,
  Schedule,
  useFeatureActivityLinking
} from "entity-app/hooks/feature-activity-linking";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

function buildActivityLinkChanges(
  selectedActivities: ActivityItem[],
  linkedActivities: ActivityItem[] | undefined
): {
  insertedLinks: ActivityItem[];
  updatedLinks: ActivityItem[];
  deletedLinks: ActivityItem[];
} {
  const insertedLinks: ActivityItem[] = [];
  const updatedLinks: ActivityItem[] = [];
  const deletedLinks: ActivityItem[] = [];

  const linkedActivitiesMap = new Map(
    linkedActivities?.map((activity) => [activity.id, activity])
  );

  selectedActivities.forEach((activity) => {
    const existingActivity = linkedActivitiesMap.get(activity.id);
    if (existingActivity) {
      if (existingActivity.link_type !== activity.link_type) {
        updatedLinks.push(activity);
      }
      linkedActivitiesMap.delete(activity.id);
    } else {
      insertedLinks.push(activity);
    }
  });

  linkedActivitiesMap.forEach((activity) => {
    deletedLinks.push(activity);
  });

  return { insertedLinks, updatedLinks, deletedLinks };
}

function generateActivityLinkPayload(
  featureId: string | undefined,
  changes: ReturnType<typeof buildActivityLinkChanges>
): any {
  if (!featureId) {
    return null;
  }

  const { insertedLinks, updatedLinks, deletedLinks } = changes;

  return {
    deletes: deletedLinks.map((activity) => ({
      source_instance_id: featureId,
      target_activity_id: activity.id
    })),
    updates: updatedLinks.map((activity) => ({
      source_instance_id: featureId,
      target_activity_id: activity.id,
      link_type: activity.link_type
    })),
    inserts: insertedLinks.map((activity) => ({
      source_instance_id: featureId,
      target_activity_id: activity.id,
      link_type: activity.link_type
    }))
  };
}

function FeatureActivityLinking({
  dialogOpenState,
  featureId,
  onSaveSuccess
}: {
  dialogOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  featureId: string | undefined;
  onSaveSuccess: () => void;
}) {
  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [, setIsActivityLinkingDialogOpen] = dialogOpenState;

  const [inEditMode, setInEditMode] = useState(false);

  const [activitySelectionChanged, setActivitySelectionChanged] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const {
    scheduleList,
    activeSchedule,
    activitiesTree,
    linkedActivities,
    isLoadingScheduleList,
    isLoadingLinkedActivities,
    resetStates,
    updateFeatureLinks,
    isUpdatingFeatureLinks,
    isLoadingActivitiesTree
  } = useFeatureActivityLinking({
    featureId,
    token,
    selectedSchedule,
    skip: !isModalOpen || inEditMode
  });

  const [selectedActivities, setSelectedActivities] = useState<ActivityItem[]>(
    []
  );

  useEffect(() => {
    if (linkedActivities) {
      setSelectedActivities(structuredClone(linkedActivities));
    }
  }, [linkedActivities]);

  useEffect(() => {
    setSelectedSchedule(activeSchedule);
  }, [scheduleList, activeSchedule]);

  const onScheduleChange = (value: Schedule) => {
    setInEditMode(true);
    setActivitySelectionChanged(false);
    setSelectedSchedule(value);
    setSelectedActivities([]);
  };

  const onCheckBoxAction = ({
    checked,
    activity
  }: {
    checked: boolean;
    activity: ActivityItem;
  }) => {
    setInEditMode(true);
    setActivitySelectionChanged(true);
    setSelectedActivities((prevActivities) => {
      if (checked) {
        const existingIndex = prevActivities.findIndex(
          (a) => a.id === activity.id
        );

        if (existingIndex !== -1) {
          // Update existing activity
          return prevActivities.map((a, index) =>
            index === existingIndex ? activity : a
          );
        }
        // Add new activity
        const updatedActivity = { ...activity, newlyLinked: true };
        return [updatedActivity, ...prevActivities];
      }
      // Remove activity
      return prevActivities.filter((a) => a.id !== activity.id);
    });
  };

  const onUnlink = useCallback((activity: ActivityItem) => {
    onCheckBoxAction({
      checked: false,
      activity
    });
  }, []);

  // useEffect(() => {
  //   console.log({ selectedSchedule, activeSchedule, selectedActivities });
  // }, [activeSchedule, selectedActivities, selectedSchedule]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setIsActivityLinkingDialogOpen(false);
    }, 500);
  };

  const onSave = async () => {
    const { insertedLinks, updatedLinks, deletedLinks } =
      buildActivityLinkChanges(selectedActivities, linkedActivities);

    const linkingPayload = generateActivityLinkPayload(featureId, {
      insertedLinks,
      updatedLinks,
      deletedLinks
    });

    // console.log("linkingPayload: ", linkingPayload);

    const response = await updateFeatureLinks(linkingPayload);

    if (response.success) {
      message.success("Updated successfully.");

      setInEditMode(false);
      closeModal();
      resetStates();
      onSaveSuccess();
    }
  };

  const linkedActivitiesDOM = useMemo(() => {
    if (isLoadingLinkedActivities) {
      return null;
    }
    if (selectedActivities && selectedActivities.length) {
      return (
        <ErrorBoundary>
          <LinkedActivitiesList
            linkedActivities={selectedActivities}
            onUnlink={onUnlink}
          />
        </ErrorBoundary>
      );
    }
    return (
      <div className="w-full h-full flex items-center justify-center relative">
        <div className="text-[#3B3B3BCC] text-xs italic relative top-[27px]">
          No activities linked
        </div>
      </div>
    );
  }, [isLoadingLinkedActivities, onUnlink, selectedActivities]);

  return (
    <FeatureActivityLinkingDialog
      open={isModalOpen}
      headerComponent={
        <ErrorBoundary>
          <ActivityLinksDialogHeader
            selectedSchedule={selectedSchedule}
            onCancel={() => {
              closeModal();
              setInEditMode(false);
              resetStates();
            }}
            onSave={() => {
              onSave();
            }}
            scheduleList={scheduleList ?? []}
            onScheduleChange={(value: any) => {
              onScheduleChange(value);
            }}
            isLoadingScheduleList={isLoadingScheduleList}
            isSaving={isUpdatingFeatureLinks}
            saveButtonDisabled={!activitySelectionChanged}
            scheduleSelectionDisabled={isLoadingActivitiesTree}
          />
        </ErrorBoundary>
      }
      lhsComponent={
        <div className="h-full max-h-full overflow-hidden flex flex-col">
          {selectedActivities && selectedActivities.length ? (
            <div className="my-5 text-xs">
              Linked Activities ({selectedActivities?.length || 0})
            </div>
          ) : null}
          <div className="grow overflow-y-auto">{linkedActivitiesDOM}</div>
        </div>
      }
      rhsComponent={
        <ErrorBoundary>
          <ActivitiesTable
            activitiesTree={activitiesTree}
            linkedActivities={selectedActivities}
            onCheckBoxAction={onCheckBoxAction}
            selectedSchedule={selectedSchedule}
            isLoadingScheduleList={isLoadingScheduleList}
          />
        </ErrorBoundary>
      }
    />
  );
}

export default FeatureActivityLinking;
