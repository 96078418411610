import EditableDetailsCardWrapper from "entity-app/shared-components/editable-details-card-wrapper/editable-details-card-wrapper";
import ActivityCard from "components/activity-card/activity-card";
import { useMemo } from "react";
import FeatureAttachments from "components/feature-attachments";
import BidPackageEdtableGeneralInfo from "./components/bid-package-editable-general-info";
import Participants from "./components/participants";
import BidPackageHeader from "./components/bid-package-header";
import OwnersInfo from "./components/owners-info";
import FeatureLinkedTopSection from "./components/faeture-linked-top-section";

function BidPackageDetails(props: {
  featureInstanceData: any;
  actions: any;
  featureId: string;
}) {
  const { featureInstanceData, actions, featureId } = props;

  const permissionsForActivityCard = useMemo(() => {
    return ["create_comment"];
  }, []);

  return (
    <div className="space-y-4 mb-4">
      <BidPackageHeader
        actions={actions}
        bidNumber={undefined}
        headerTitle={featureInstanceData?.title}
        status={featureInstanceData?.feature_workflow_instance?.workflow_status}
      />
      <FeatureLinkedTopSection
        actions={actions}
        featureInstanceData={featureInstanceData}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col gap-4">
          <EditableDetailsCardWrapper title="General Information">
            <BidPackageEdtableGeneralInfo
              featureInstanceData={featureInstanceData}
              actions={actions}
            />
          </EditableDetailsCardWrapper>
          <EditableDetailsCardWrapper title="Participants">
            <Participants
              featureInstanceData={featureInstanceData}
              actions={actions}
            />
          </EditableDetailsCardWrapper>
        </div>
        <div className="h-[800px] flex flex-col gap-y-4">
          <ActivityCard
            historyTabContents={<div>History</div>}
            featureId={featureId}
            featureKey="feature_instance_id"
            permissions={permissionsForActivityCard}
            showHistoryTab={false}
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="h-[220px]">
            <EditableDetailsCardWrapper title="Owners">
              <OwnersInfo
                featureInstanceData={featureInstanceData}
                actions={actions}
              />
            </EditableDetailsCardWrapper>
          </div>
          <div className="h-[563px]">
            <EditableDetailsCardWrapper title="Attachments">
              <FeatureAttachments
                featureId={featureId}
                featureKey="feature_instance_id"
              />
            </EditableDetailsCardWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidPackageDetails;
