import {
  ColDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  GridOptions
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Checkbox, Tooltip } from "antd";
import NoLinkIcon from "components/svg-icons/no-link-icon";
import { ScheduleTaskType } from "pages/submittal-schedule-linking/models";
import { useEffect, useMemo, useRef, useState } from "react";
import ExpandIconPath from "assets/svg/expand-icon.svg";
import CollapseIconPath from "assets/svg/collapse-icon.svg";
import { GridLoadingIndicator } from "components/widgets";
import { DateFilter, DateUtils } from "utils/dateutils";
import { LinkTypes } from "entity-app/constants";
import CiqAgSearch from "components/ciq-ag-search";

function CellWithDateCheckbox(params: any) {
  const { data, isStartDate, context } = params;

  const { onCheckBoxAction, linkedActivities } = context;

  if (isStartDate) {
    const startDate = data?.start_date
      ? DateUtils.format(data.start_date, "MM-DD-YYYY")
      : "";

    if (startDate === "") return "";

    const isLinkedByF2S = linkedActivities.some(
      (activity: any) =>
        activity.id === data.id &&
        activity.link_type === LinkTypes.finish_to_start
    );

    return (
      <div className="flex w-full justify-end items-center">
        {data.type !== 5 ? (
          <Checkbox
            checked={isLinkedByF2S}
            onChange={(e: any) => {
              onCheckBoxAction({
                checked: e.target.checked,
                activity: {
                  link_type: LinkTypes.finish_to_start,
                  start_date: data.start_date,
                  end_date: data.end_date,
                  id: data.id,
                  source_task_id: data.source_task_id,
                  text: data.text,
                  type: data.type
                }
              });
            }}
          >
            <i>{startDate}</i>
          </Checkbox>
        ) : (
          <i className="mr-2">{startDate}</i>
        )}
      </div>
    );
  }

  const endDate = data?.end_date
    ? DateUtils.format(data.end_date, "MM-DD-YYYY")
    : "";
  if (endDate === "") return "";

  const isLinkedByF2F = linkedActivities.some(
    (activity: any) =>
      activity.id === data.id &&
      activity.link_type === LinkTypes.finish_to_finish
  );

  return (
    <div className="flex w-full justify-end items-center">
      {data.type !== 5 ? (
        <Checkbox
          checked={isLinkedByF2F}
          onChange={(e: any) => {
            onCheckBoxAction({
              checked: e.target.checked,
              activity: {
                link_type: LinkTypes.finish_to_finish,
                start_date: data.start_date,
                end_date: data.end_date,
                id: data.id,
                source_task_id: data.source_task_id,
                text: data.text,
                type: data.type
              }
            });
          }}
        >
          <i>{endDate}</i>
        </Checkbox>
      ) : (
        <i className="mr-2">{endDate}</i>
      )}
    </div>
  );
}

function ActivitiesTable(props: {
  activitiesTree: any[];
  linkedActivities: any[];
  onCheckBoxAction: (data: any) => void;
  selectedSchedule: any;
  isLoadingScheduleList: boolean;
}) {
  const {
    activitiesTree,
    linkedActivities,
    onCheckBoxAction,
    selectedSchedule,
    isLoadingScheduleList
  } = props;

  const gridRef = useRef<AgGridReact<any>>(null);

  const [isGridReady, setGridReady] = useState(false);

  const cellWithTaskId = (params: any) => {
    if (params.data.type !== 5) {
      return params.data.source_task_id;
    }

    if (params?.node?.allChildrenCount) {
      return params.data.source_task_id;
    }

    return (
      <Tooltip
        title="You can only link an activity, milestones can't be linked."
        placement="topLeft"
      >
        <div className="flex items-center">
          <div className="flex h-5 w-5 items-center">
            <NoLinkIcon fill="#3B3B3B33" size={18} />
          </div>
          <i className="mr-2">{params.data.source_task_id}</i>
        </div>
      </Tooltip>
    );
  };

  function setNodesCollapseandExpand(id: string, isExpanded: boolean) {
    gridRef.current?.api.forEachNode((node) => {
      if (node.data?.source_task_id === id) {
        // const isExpanded = !node.expanded;
        gridRef.current?.api.setRowNodeExpanded(node, isExpanded);
        // eslint-disable-next-line array-callback-return
        node.allLeafChildren.map((leafNode) => {
          gridRef.current?.api.setRowNodeExpanded(
            leafNode,
            leafNode.expanded === isExpanded
              ? leafNode.expanded
              : !leafNode.expanded
          );
        });
      }
    });
  }

  function getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    const result: (string | MenuItemDef)[] = [
      {
        // custom item
        name: "Expand All",
        action: () => {
          setNodesCollapseandExpand(
            params?.node?.data?.source_task_id || "",
            true
          );
        },
        icon: `<img src='${ExpandIconPath}'>`
      },
      {
        // custom item
        name: "Collapse All",
        action: () => {
          setNodesCollapseandExpand(
            params?.node?.data?.source_task_id || "",
            false
          );
        },
        icon: `<img src='${CollapseIconPath}'>`
      }
    ];

    return (params?.node?.allLeafChildren || []).length > 1 ? result : [];
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      menuTabs: [],
      getContextMenuItems,
      cellRendererParams: {
        suppressCount: true
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      colId: "source_task_id",
      field: "source_task_id",
      headerName: "ID",
      headerTooltip: "ID",
      tooltipValueGetter: (params) => {
        if (params.data.type === 5 && !params.node?.allChildrenCount) {
          return "";
        }
        return params.value;
      },
      showRowGroup: true,
      filter: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: cellWithTaskId
      },
      sort: "desc",
      width: 80
    },
    {
      colId: "text",
      field: "text",
      headerName: "Name",
      headerTooltip: "NAME",
      tooltipField: "text",
      filter: true,
      minWidth: 150
    },
    {
      colId: "start_date",
      field: "start_date",
      headerName: "Start Date",
      headerTooltip: "START DATE",
      headerClass: "dateHedderCell",
      cellRenderer: CellWithDateCheckbox,
      cellRendererParams: {
        isStartDate: true
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (params) =>
        params.value ? DateUtils.format(params.value, "MM-DD-YYYY") : "",
      minWidth: 150,
      maxWidth: 150,
      filter: true
    },
    {
      colId: "end_date",
      field: "end_date",
      headerName: "End Date",
      headerTooltip: "END DATE",
      headerClass: "dateHedderCell",
      cellRenderer: CellWithDateCheckbox,
      cellRendererParams: {
        isStartDate: false
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (params) =>
        params.value ? DateUtils.format(params.value, "MM-DD-YYYY") : "",
      minWidth: 150,
      maxWidth: 150,
      filter: true
    }
  ]);

  const gridOptions: GridOptions = {
    defaultColDef,
    suppressCopyRowsToClipboard: true,
    getContextMenuItems
  };

  const getDataPath = useMemo(() => {
    return (data: any) => {
      return data.org;
    };
  }, []);

  useEffect(() => {
    if (isGridReady) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [activitiesTree, isGridReady, selectedSchedule]);

  useEffect(() => {
    if (isGridReady && gridRef.current && linkedActivities) {
      gridRef.current.api.refreshCells({
        columns: ["start_date", "end_date"],
        force: true
      });
    }
  }, [isGridReady, linkedActivities]);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <div className="py-2 uppercase text-xs flex items-center justify-between">
        <div>Link Activities</div>
        <CiqAgSearch
          key="feature_activity_linking_search"
          gridRef={gridRef}
          placeholder="Search Activities"
        />
      </div>
      <div className="ag-theme-alpine flex-1 relative">
        {!selectedSchedule && !isLoadingScheduleList && (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center text-[#3B3B3BCC] text-xs italic">
            Select the Schedule to display Activities. The Schedule will apply
            to the entire project.
          </div>
        )}
        {isLoadingScheduleList || (selectedSchedule && !activitiesTree) ? (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : null}
        <AgGridReact<ScheduleTaskType>
          ref={gridRef}
          rowData={activitiesTree}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          headerHeight={40}
          onGridReady={() => {
            setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          treeData
          rowSelection="single"
          groupDefaultExpanded={-1}
          getDataPath={getDataPath}
          groupDisplayType="custom"
          suppressRowDrag
          suppressMovableColumns
          suppressCellFocus
          suppressDragLeaveHidesColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          suppressLoadingOverlay
          suppressNoRowsOverlay
          context={{ linkedActivities, onCheckBoxAction }}
        />
      </div>
    </div>
  );
}

export default ActivitiesTable;
