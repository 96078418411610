import { useMemo } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useParams } from "react-router";
import {
  coldefDateField,
  coldefNavigation,
  coldefSingleField,
  coldefUser
} from "entity-app/shared-components/log-render-components/col-defs";
import {
  ArrayStringCellRenderer,
  twoRowCellRenderer
} from "entity-app/shared-components/log-render-components/cell-renders";

export const usePreconstrutionAgGrid = (props: { featureKey: string }) => {
  const { featureKey } = props;
  const { projectId } = useParams() as any;

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    resizable: true,
    menuTabs: ["filterMenuTab"],
    minWidth: 100
  };

  const columnDefs = useMemo(
    () =>
      [
        coldefNavigation(
          {
            field: "auto_inc_value",
            headerName: "ID",
            pinned: "left",
            lockPosition: "left",
            checkboxSelection: true,
            width: 100,
            sort: "asc"
          },
          projectId,
          featureKey
        ),
        coldefSingleField({
          field: "number",
          headerName: "NUMBER",
          pinned: "left",
          lockPosition: "left",
          width: 120
        }),
        coldefSingleField({
          field: "title",
          headerName: "BID PACKAGE TITLE",
          width: 180,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: twoRowCellRenderer,
          cellRendererParams: {
            featureKey,
            projectId
          }
        }),
        coldefSingleField({ field: "wave", headerName: "WAVE", width: 100 }),
        coldefSingleField({
          field: "bid_scope",
          headerName: "BID SCOPE",
          cellRenderer: ArrayStringCellRenderer,
          cellRendererParams: {
            // featureKey
          },
          valueGetter: ({ data }) => {
            if (!data || !data.bid_scope) return [];
            return data.bid_scope.map((scope: any) => ({
              id: scope.id,
              name: `${scope.spec_section_number} - ${scope.spec_section_title}`
            }));
          },
          tooltipField: undefined,
          menuTabs: [],
          width: 300,
          minWidth: 300
        }),
        coldefSingleField({ field: "workflow_status", headerName: "STATUS" }),

        coldefDateField({
          field: "next_planned_deadline",
          headerName: "NEXT DEADLINE"
        }),
        coldefDateField({ field: "due_date", headerName: "DUE DATE" }),
        coldefSingleField({ field: "risk", headerName: "RISK", width: 130 }),
        coldefSingleField({
          field: "float",
          headerName: "BID PACKAGE FLOAT",
          width: 130
        }),
        coldefSingleField({
          field: "awarded_subcontractor_name",
          headerName: "AWARDED SUBCONTRACTOR"
        }),
        coldefSingleField({
          field: "awarded_value",
          headerName: "AWARDED VALUE",
          width: 160
        }),
        coldefSingleField({
          field: "estimated_value",
          headerName: "ESTIMATED VALUE",
          width: 160
        }),
        coldefUser({
          def: { headerName: "PRECONSTRUCTION LEAD" },
          user: {
            id: "preconstruction_lead_id",
            firstName: "preconstruction_lead_first_name",
            lastName: "preconstruction_lead_last_name"
          }
        }),
        coldefUser({
          def: { headerName: "OPERATIONS LEAD" },
          user: {
            id: "operations_lead_id",
            firstName: "operations_lead_first_name",
            lastName: "operations_lead_last_name"
          }
        }),
        coldefSingleField({
          field: "bid_package_template_name",
          headerName: "BID PACKAGE TEMPLATE"
        }),
        coldefSingleField({
          field: "no_of_bidders",
          headerName: "NO. OF BIDDERS",
          width: 160
        }),
        coldefSingleField({
          field: "bidding_subcontractors",
          headerName: "BIDDING SUBCONTRACTORS",
          cellRenderer: ArrayStringCellRenderer,
          cellRendererParams: {
            // featureKey
          },
          tooltipField: undefined,
          menuTabs: [],
          width: 300,
          minWidth: 300
        })
      ] as (ColDef<any> | ColGroupDef<any>)[],
    [projectId, featureKey]
  );
  return {
    columnDefs,
    defaultColDef
  };
};
