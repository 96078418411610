import { Button, Form, Input, message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import "./bid-package-planning-panel.scss";
import { ProjectContext } from "context/ProjectProvider";
import {
  useBidPackagePlanning,
  WorkflowInstanceOffset
} from "entity-app/hooks/bid-package-planning";
import { useParams } from "react-router";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import { LinkOutlined } from "@ant-design/icons";
import CIQDatePicker from "components/custom-date-picker";
import moment from "moment";
import { LinkTypes } from "entity-app/constants";
import { restrictInputToNumbers } from "utils/inpututils";
import ActivityItem from "./activity-item";
import FeatureActivityLinking from "./feature-activity-linking";

function BidPackagePlanningPanel() {
  const { bidPackageId } = useParams() as any;
  const [form] = Form.useForm();

  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [isActivityLinkingDialogOpen, setIsActivityLinkingDialogOpen] =
    useState(false);

  const [scheduleROSDatePickerOpen, setScheduleROSDatePickerOpen] =
    useState(false);

  const {
    featureLinks,
    updateWorkflowInstanceOffset,
    updateWorkflowInstance,
    refetch: refetchPlanningData
  } = useBidPackagePlanning({
    featureId: bidPackageId,
    token
  });

  const linkedActivities = useMemo(() => {
    const activites =
      featureLinks?.PLANNING_ONLY_MATERIAL?.links_as_source.filter(
        (link: any) => link.target_gantt_task
      );

    activites?.sort((a: any, b: any) => {
      const aDateString =
        a.link_type === LinkTypes.finish_to_start
          ? a.target_gantt_task.start_date
          : a.target_gantt_task.end_date;
      const bDateString =
        b.link_type === LinkTypes.finish_to_start
          ? b.target_gantt_task.start_date
          : b.target_gantt_task.end_date;

      return moment(aDateString).diff(moment(bDateString));
    });

    return activites || null;
  }, [featureLinks]);

  const incomingFieldValues = useMemo(() => {
    if (!featureLinks) {
      return null;
    }

    const fieldValuesToSet: any = {};

    Object.keys(featureLinks).forEach((key: any) => {
      if (featureLinks?.[key]?.workflow_instance_offsets) {
        featureLinks?.[key]?.workflow_instance_offsets.forEach(
          (offset: WorkflowInstanceOffset) => {
            fieldValuesToSet[offset.id] = offset.duration;
          }
        );
      }
    });
    if (
      featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
        ?.manual_entry_date
    ) {
      fieldValuesToSet[
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
      ] = DateUtils.dateTimeObj(
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
          ?.manual_entry_date
      );
    } else {
      fieldValuesToSet[
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
      ] = null;
    }
    return fieldValuesToSet;
  }, [featureLinks]);

  useEffect(() => {
    if (featureLinks && incomingFieldValues) {
      form.setFieldsValue(incomingFieldValues);
    }
  }, [featureLinks, form, incomingFieldValues]);

  const onBlur = async (change: { offsetId: string; value: any }) => {
    if (incomingFieldValues?.[change.offsetId] === change.value) {
      return;
    }

    if (Number.isNaN(change.value) || change.value < 0) {
      form.setFieldsValue({
        [change.offsetId]: incomingFieldValues?.[change.offsetId]
      });
      return;
    }

    const updateResponse = await updateWorkflowInstanceOffset(change);

    if (updateResponse.data.update_feature_workflow_instance_offsets_by_pk) {
      message.success("Updated successfully.");
    } else {
      message.error("Failed to update.");
    }
  };

  const onDateChange = async (change: { id: string; value: any }) => {
    const payload = {
      id: change.id,
      set: {
        manual_entry_date: change.value
      }
    };
    const updateResponse = await updateWorkflowInstance(payload);

    if (updateResponse.data.update_feature_workflow_instance_by_pk) {
      message.success("Updated successfully.");
    }
  };

  const footerForROJDatePicker = (
    <div className="flex justify-center">
      <Button
        onClick={() => {
          onDateChange({
            id: featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
              ?.id!,
            value: null
          });
          form.setFieldsValue({
            [featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
              ?.id!]: null
          });
          setScheduleROSDatePickerOpen(false);
        }}
      >
        Clear Date
      </Button>
    </div>
  );

  return (
    <div className="bid-package-planning-panel px-3 py-3 h-full max-h-full overflow-x-hidden overflow-y-auto">
      {!featureLinks ? null : (
        <>
          <Form form={form} layout="vertical">
            {featureLinks?.PLANNING_ONLY_SUBMITTAL && (
              <div className="border-type-1 border-0 border-b pb-1">
                <div className="text-xs font-semibold tracking-wide mb-2">
                  Submittal Plan
                </div>
                <div className="bg-[#F7F6F4] px-2 border-type-2 border-0 border-b">
                  {featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_milestones.map(
                    (link: any) => {
                      return (
                        <div
                          className="flex justify-between text-xs py-1"
                          key={link.id}
                        >
                          <div className="italic">{link.name}</div>
                          <div>
                            {DateUtils.format(link.planned_date, "MM-DD-YYYY")}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="flex gap-x-3 mt-2">
                  {featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_offsets.map(
                    (offset: any) => {
                      return (
                        <div className="w-1/2" key={offset.id}>
                          <Form.Item label={offset.name} name={offset.id}>
                            <Input
                              type="number"
                              suffix="Days"
                              onKeyDown={restrictInputToNumbers}
                              onBlur={(e: any) => {
                                onBlur({
                                  offsetId: offset.id,
                                  value: parseInt(e.target.value, 10)
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}

            {featureLinks?.PLANNING_ONLY_MATERIAL && (
              <div className="border-type-1 border-0 border-b pb-1 mt-3">
                <div className="text-xs font-semibold tracking-wide mb-2">
                  Material Plan
                </div>
                <div className="bg-[#F7F6F4] px-2 border-type-2 border-0 border-b">
                  {featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_milestones.map(
                    (link: any) => {
                      return (
                        <div
                          className="flex justify-between text-xs py-1"
                          key={link.id}
                        >
                          <div className="italic">{link.name}</div>
                          <div>
                            {DateUtils.format(link.planned_date, "MM-DD-YYYY")}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="flex gap-x-3 mt-2">
                  {featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_offsets.map(
                    (offset: any) => {
                      return (
                        <div className="w-1/2" key={offset.id}>
                          <Form.Item label={offset.name} name={offset.id}>
                            <Input
                              type="number"
                              suffix="Days"
                              onKeyDown={restrictInputToNumbers}
                              onBlur={(e: any) => {
                                onBlur({
                                  offsetId: offset.id,
                                  value: parseInt(e.target.value, 10)
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}

            <div className="mt-3">
              <div className="text-xs font-semibold tracking-wide mb-2">
                Schedule
              </div>
              <div className="w-full flex items-stretch">
                <div className="bg-type-1 mb-3 w-1/2 flex items-center px-2 italic text-xs">
                  Required on Site
                </div>
                <div className="w-1/2">
                  <Form.Item
                    className="w-full border-0 border-b border-type-1"
                    name={
                      featureLinks?.PLANNING_ONLY_ACTIVITY
                        ?.feature_workflow_instance?.id
                    }
                  >
                    <CIQDatePicker
                      onFocus={() => setScheduleROSDatePickerOpen(true)}
                      onBlur={() => setScheduleROSDatePickerOpen(false)}
                      open={scheduleROSDatePickerOpen}
                      className="w-full"
                      placeholder="Select date"
                      format={DATE_FORMAT_MMDDYYYY}
                      bordered={false}
                      onChange={(date: any) => {
                        const saveDate =
                          DateUtils.formatDateWithLunchTime(date);
                        onDateChange({
                          id: featureLinks?.PLANNING_ONLY_ACTIVITY
                            ?.feature_workflow_instance?.id!,
                          value: saveDate
                        });
                        setScheduleROSDatePickerOpen(false);
                      }}
                      renderExtraFooter={() => {
                        if (
                          featureLinks?.PLANNING_ONLY_ACTIVITY
                            ?.feature_workflow_instance?.id &&
                          incomingFieldValues?.[
                            featureLinks?.PLANNING_ONLY_ACTIVITY
                              ?.feature_workflow_instance?.id!
                          ]
                        ) {
                          return footerForROJDatePicker;
                        }
                        return null;
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div>
              <div className="w-full flex items-center justify-between mb-2">
                <div className="text-color-1 text-xs">
                  ACTIVITIES ({linkedActivities?.length || 0})
                </div>
                <Button
                  type="link"
                  size="small"
                  className="ciq-button-reset text-xs ciq-button-1 h-[20px] px-0"
                  onClick={() => setIsActivityLinkingDialogOpen(true)}
                >
                  {linkedActivities && linkedActivities?.length > 0
                    ? "Modify"
                    : "Modify"}{" "}
                  <LinkOutlined className="!ml-1" />
                </Button>
              </div>
              <div className="flex flex-col gap-y-2">
                {linkedActivities?.map((activity: any) => {
                  return (
                    <ActivityItem
                      key={activity.id}
                      activity={activity.target_gantt_task}
                      linkType={activity.link_type}
                    />
                  );
                })}
              </div>
            </div>
          </Form>
          {isActivityLinkingDialogOpen && (
            <FeatureActivityLinking
              dialogOpenState={[
                isActivityLinkingDialogOpen,
                setIsActivityLinkingDialogOpen
              ]}
              featureId={featureLinks?.PLANNING_ONLY_MATERIAL?.id}
              onSaveSuccess={refetchPlanningData}
            />
          )}
        </>
      )}
    </div>
  );
}

export default BidPackagePlanningPanel;
