// services to communicate with BE.

import { BASE_URL, GRAPHQL_API_URL } from "config/config";
import CIQClient, { HTTPClientResponse } from "services/ciq-client";
import ErrorManager from "utils/cumulative-error-message/error-manager";

export const getGQLQueryData = async (
  token: string,
  queryDefinition: string,
  variables?: any,
  signal?: AbortSignal
): Promise<HTTPClientResponse> => {
  if (!token) {
    return {
      success: false,
      errorMsg: "Token is required",
      response: null
    };
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    signal
  };

  const postPayload: any = {
    url: GRAPHQL_API_URL,
    data: {
      query: `query ${queryDefinition}`
    },
    config
  };

  if (variables) {
    postPayload.data.variables = variables;
  }

  const response: HTTPClientResponse = await CIQClient.post(postPayload);

  // Below condition is needed because hasura returns error in response.data.errors and status code is always 200
  if (response.data.errors) {
    const errorMsg = ErrorManager().handleError(response.data.errors[0]);
    return {
      success: false,
      error: response.data.errors,
      errorMsg,
      response
    };
  }

  return response;
};

export const modifyFeatureLinks = async (
  token: string,
  request: {
    featureId: string;
    links: {
      deletes: { source_activity_id: string; target_activity_id: string }[];
      inserts: {
        source_activity_id: string;
        target_activity_id: string;
        link_type: number;
      }[];
      updates: {
        source_activity_id: string;
        target_activity_id: string;
        link_type: number;
      }[];
    };
  }
): Promise<HTTPClientResponse> => {
  const response = await CIQClient.post({
    url: `${BASE_URL}/features/linkings`,
    data: request,
    config: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });

  return response;
};
