import { useContext } from "react";
import CiqAgGrid from "components/ciq-ag-grid";
import { AgGridReact } from "ag-grid-react";
import { ProjectContext } from "context/ProjectProvider";
import { TLinkedSubmittal } from "../models";
import { submittalColDefs } from "../col-defs/submittal-col-def";

function SubmittalGrid({
  submittals,
  gridRef
}: {
  submittals: Array<TLinkedSubmittal>;
  gridRef: React.RefObject<AgGridReact<TLinkedSubmittal>>;
}) {
  const { projectDetails } = useContext(ProjectContext);
  return (
    <div className="h-72">
      <CiqAgGrid
        {...{
          gridRef,
          columnDefs: submittalColDefs,
          rowData: submittals,
          sideBar: null,
          context: { projectDetails },
          suppressMovableColumns: true
        }}
      />
    </div>
  );
}
export default SubmittalGrid;
