/* eslint-disable react/destructuring-assignment */
import GoverningActivityCellValueRenderer from "../governing-item-cell-renderer/governing-activity-cell-value-renderer";

function GoverningActivityCellRenderer(params: any) {
  const { isDeleted } = params;
  return (
    <div>
      <GoverningActivityCellValueRenderer
        params={{ isDeleted, data: params.data }}
      />
    </div>
  );
}

export default GoverningActivityCellRenderer;
