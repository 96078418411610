import attachmentIconPath from "assets/svg/attachment-icon.svg";
import { DateUtils } from "utils/dateutils";
import docIconPath from "assets/svg/doc-icon.svg";

function AttachmentItem(props: {
  attachment: any;
  onClick: (attachment: any) => void;
}) {
  const { attachment, onClick } = props;
  return (
    <button
      type="button"
      className="attachment-item"
      onClick={() => onClick(attachment)}
    >
      <div>
        <div className="flex mb-1">
          <div className="w-5">
            <img src={attachmentIconPath} alt="" />
          </div>
          <div className="grow">
            {attachment?.created_at
              ? DateUtils.format(attachment?.created_at)
              : ""}
          </div>
          <div className="grow">{`${
            attachment?.created_by_user?.first_name || ""
          } ${attachment?.created_by_user?.last_name || ""}`}</div>
        </div>
        <div className="flex">
          <div className="w-5">
            <img src={docIconPath} alt="" />
          </div>
          <div className="grow truncate">{attachment?.file_name}</div>
        </div>
      </div>
    </button>
  );
}

export default AttachmentItem;
