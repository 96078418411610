/* eslint-disable react/destructuring-assignment */
import { Tooltip } from "antd";
import LinkIcon from "components/svg-icons/link-icon";
import GoverningActivityCellValueRenderer from "./governing-activity-cell-value-renderer";

function GoverningItemCellRenderer(params: any) {
  const { isDeleted } = params;
  const hasGoverningActivity = params?.data?.new_governing_task?.length > 0;
  const hasGoverningMaterial = params?.data?.new_governing_material?.length > 0;

  let newValue: any;
  let oldValue: any;
  let newMaterialName: any;
  let oldMaterialName: any;
  let newMaterialId: any;
  let oldMaterialId: any;
  let newMaterialSequenceId: any;
  let oldMaterialSequenceId: any;

  if (hasGoverningMaterial) {
    newValue =
      params?.data?.new_governing_material?.length > 0
        ? params?.data?.new_governing_material[0]
        : [];

    oldValue =
      params?.data?.old_governing_material?.length > 0
        ? params?.data?.old_governing_material[0]
        : [];

    newMaterialId = newValue?.id || "";
    oldMaterialId = oldValue?.id || "";

    newMaterialName = newValue?.name || "";
    oldMaterialName = oldValue?.name || "";

    newMaterialSequenceId = newValue?.material_sequence_id || "";
    oldMaterialSequenceId = oldValue?.material_sequence_id || "";
  }

  return (
    <div>
      {hasGoverningActivity && (
        <GoverningActivityCellValueRenderer
          params={{ isDeleted, data: params.data }}
        />
      )}
      {hasGoverningMaterial && !hasGoverningActivity && (
        <div className="flex w-full py-1">
          {newMaterialId !== oldMaterialId && (
            <div className="flex-col bg-gray-200 h-10 w-full px-1">
              <div className="flex items-center h-5 w-full space-x-2">
                <span className="flex text-[9px] font-normal h-full items-center">
                  New:{" "}
                </span>
                <span className="flex space-x-1 text-[12px] font-semibold h-full items-center">
                  <LinkIcon />{" "}
                  <div className="min-w-4">
                    <Tooltip
                      destroyTooltipOnHide
                      mouseLeaveDelay={0}
                      title={`${newMaterialSequenceId} ${newMaterialName}`}
                      showArrow
                    >
                      {`${newMaterialSequenceId} ${newMaterialName}`}
                    </Tooltip>
                  </div>
                </span>
              </div>
              <div className="flex h-5 items-center font-normal w-full space-x-2">
                <div className="flex text-[9px] font-normal h-full items-center">
                  Was:{" "}
                </div>
                <div className="flex space-x-1 text-[11px] font-normal h-full items-center">
                  <LinkIcon />{" "}
                  <div>
                    <Tooltip
                      destroyTooltipOnHide
                      mouseLeaveDelay={0}
                      title={`${oldMaterialSequenceId} ${oldMaterialName}`}
                      showArrow
                    >
                      {`${oldMaterialSequenceId} ${oldMaterialName}`}
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )}

          {newMaterialId === oldMaterialId && newMaterialId !== undefined && (
            <div className="flex space-x-1 h-full items-center">
              <LinkIcon />
              <Tooltip
                destroyTooltipOnHide
                mouseLeaveDelay={0}
                title={`${newMaterialSequenceId} ${newMaterialName}`}
                showArrow
              >
                <div>
                  <span className="truncate" style={{ width: "50%" }}>
                    {`${newMaterialSequenceId} ${newMaterialName}`}
                  </span>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GoverningItemCellRenderer;
