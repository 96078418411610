import BidPackagePlanningPanel from "components/bid-package-planning-panel";
import EntityDateblock from "entity-app/shared-components/entity-dateblock";
import { TFeatureWorkflowInstance } from "entity-app/models";
import EntityRiskStatementView from "entity-app/shared-components/entity-dateblock/components/entity-risk-statement/entity-risk-statement-view";
import FeatureToFeatureLinksPanel from "components/feature-to-feature-links-panel";
import ErrorBoundary from "components/error-boundary";
import ProjectWorkflowTemplateDropdown from "../workflow-template-dropdown";

function HeadingDateBlock(props: { featureInstanceData: any; actions: any }) {
  const { featureInstanceData, actions } = props;
  if (!featureInstanceData) return null;
  const { feature_workflow_instance: featureWorkflowInstance } =
    featureInstanceData as {
      feature_workflow_instance: TFeatureWorkflowInstance;
    };

  return (
    <div className="flex w-full h-14 justify-between items-center px-3 py-2 text-[#3B3B3BCC]">
      <div className="uppercase text-sm font-semibold">
        BID PACKAGE SCHEDULE
      </div>
      <EntityRiskStatementView
        actions={actions}
        featureWorkflowInstance={featureWorkflowInstance}
      />
      <div className="flex items-center space-x-1 font-medium">
        <div>BID PACKAGE TEMPLATE:</div>
        <ProjectWorkflowTemplateDropdown
          actions={actions}
          featureId={4}
          featureWorkflowInstance={featureWorkflowInstance}
        />
      </div>
      <div>*Governing Date</div>
    </div>
  );
}

function FeatureLinkedTopSection(props: {
  featureInstanceData: any;
  actions: any;
}) {
  const { featureInstanceData, actions } = props;
  return (
    <div className="w-full h-[470px] border border-black border-solid flex">
      <div className="w-[200px] shrink-0 overflow-y-auto border-0 border-r border-solid border-[#3b3b3b80]">
        <FeatureToFeatureLinksPanel featureId={featureInstanceData?.id} />
      </div>
      <div className="grow overflow-auto border-0 border-r border-solid border-[#3b3b3b80]">
        <HeadingDateBlock
          actions={actions}
          featureInstanceData={featureInstanceData}
        />
        <div className="overflow-auto px-3">
          <EntityDateblock
            actions={actions}
            workflowInstanceMilestones={
              featureInstanceData?.workflow_instance_milestones
            }
            featureWorkflowInstance={
              featureInstanceData?.feature_workflow_instance
            }
            showFloat={featureInstanceData?.feature?.show_float}
          />
        </div>
      </div>
      <div className="w-[280px] shrink-0 overflow-y-auto">
        <ErrorBoundary>
          <BidPackagePlanningPanel />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default FeatureLinkedTopSection;
