import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import ActivitiesFilterForm, {
  TDateRangeActivity
} from "../components/activities-filter-form";
import ScheduleActivitiesReportComponent from "./schedule-activities-components";

import "./schedule-activities-components.scss";

function ScheduleActivitiesReport() {
  const history = useHistory();

  const { projectId } = useParams() as any;
  const [openFilterForm, setOpenFilterForm] = useState(true);
  const [reportFilter, setReportFilter] = useState<TDateRangeActivity>();

  const gotoReports = () => {
    history.push(`/project/${projectId}/reports`);
  };

  return (
    <div className="flex flex-col h-full w-full schedule-activities-components">
      <Space
        className="w-fit px-4 py-2 font-medium space-x-1 cursor-pointer"
        onClick={gotoReports}
      >
        <ArrowLeftOutlined />
        Reports
      </Space>
      <div className="flex flex-col h-full bg-white px-4 py-1">
        {reportFilter && (
          <ScheduleActivitiesReportComponent
            openFilter={() => setOpenFilterForm(true)}
            reportFilter={reportFilter}
          />
        )}
      </div>
      {openFilterForm && (
        <ActivitiesFilterForm
          open={openFilterForm}
          closable={!!reportFilter}
          onCancel={() => {
            setOpenFilterForm(false);
          }}
          onFinish={(e: any) => {
            setOpenFilterForm(false);
            setReportFilter(undefined); // clear previous so that component rerenders on selecting new combination
            setTimeout(() => {
              setReportFilter(e);
            });
          }}
          onBack={gotoReports}
        />
      )}
    </div>
  );
}
export default ScheduleActivitiesReport;
