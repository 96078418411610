import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { DESIGN_PACKAGE_LIST_VIEW } from "../queries";

export const useDesignPackageData = () => {
  const { gqlClientForProject } = useContext(ProjectContext);

  const { data: designPackageList, refetch: refetchDesignPackageList } =
    useCIQQuery<{
      design_package_list_view: Array<any>;
    }>(DESIGN_PACKAGE_LIST_VIEW, {
      variables: {},
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });
  return {
    designPackageList,
    refetchDesignPackageList
  };
};
