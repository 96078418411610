import { useFeatureAttachments } from "entity-app/hooks/feature-attachments";
import "./feature-attachments.scss";
import { message } from "antd";
import { getFileDownloadURL } from "services/file-mgmt";
import { downloadFileFromS3andOpenInNewTab } from "utils/utils";
import AttachmentItem from "./attachment-item";
import { ErrorMessages, noDocumentMessage } from "../../constants";

function FeatureAttachments(props: { featureId: string; featureKey: string }) {
  const { featureId, featureKey } = props;
  const { attachments, error, loading } = useFeatureAttachments(
    featureId,
    featureKey
  );

  const onAttachmentClick = async (data: any) => {
    message.success(ErrorMessages.comments.fileDownloadStarted);
    try {
      const fileDownloadURLResp: any = await getFileDownloadURL([
        data.blob_key
      ]);
      await downloadFileFromS3andOpenInNewTab(
        fileDownloadURLResp.data.success.urls[data.blob_key],
        data.file_type
      );
    } catch (ex: any) {
      // console.log("ex ", ex);
      const errorMsg: string =
        ex.response?.data?.error ||
        ex.message ||
        "Unable to download attachment";
      message.error(errorMsg);
    }
  };

  if ((loading && (!attachments || !attachments.length)) || error) {
    return null;
  }

  if (attachments && !attachments.length) {
    return (
      <div className="w-full h-full flex items-center justify-center text-gray-400 text-xs">
        {noDocumentMessage}
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {attachments?.map((attachment: any) => (
        <AttachmentItem
          key={attachment.id}
          attachment={attachment}
          onClick={onAttachmentClick}
        />
      ))}
    </div>
  );
}

export default FeatureAttachments;
