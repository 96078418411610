import { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { DateFilter, DateUtils } from "utils/dateutils";
import { cellWithDataDifference } from "components/schedule-impact-cells/data-difference-cell";
import SubmittalScheduleImpact from "./submittal-schedule-impact";
import MaterialScheduleImpact from "./material-schedule-impact";
import {
  headerHeight,
  rowHeight,
  titleHeight,
  padding,
  maxRowCount
} from "./utils";
import {
  ScheduleComparisonResultType,
  ScheduleImpactResultType
} from "../compare-schedule-2/model";

type Props = {
  scheduleComparison: ScheduleComparisonResultType;
  scheduleImpact: ScheduleImpactResultType;
  gridRef: any;
};

function GridCompareSchedulePage(props: Props) {
  const { scheduleComparison, scheduleImpact, gridRef } = props;
  const [taskData, setTaskData] = useState<Array<any>>([]);

  const getStructureMaterialLinkedObject = (
    material: any,
    materialId: string
  ) => {
    const mdb = material.MDB;
    return {
      MDB: { new: mdb.new, old: mdb.old },
      action: material?.action || "No action needed",
      material_id: materialId,
      new_governing_task: material?.new_governing_task
        ? material?.new_governing_task
        : [],
      old_governing_task: material?.old_governing_task
        ? material?.old_governing_task
        : [],
      linked_submittals: material?.linked_submittals
        ? material?.linked_submittals
        : []
    };
  };

  const isActivityGoverningForMaterial = (
    materials: any,
    impactedActivityId: any,
    isActivityDeleted: boolean = false
  ) => {
    let isGoverning = false;
    if (impactedActivityId && materials && materials.length > 0) {
      for (let i: number = 0, len = materials.length; i < len; i += 1) {
        const material = materials[i];

        if (isActivityDeleted) {
          // old_governing_task has always zero or max one element
          const oldGoverningActivity =
            material.old_governing_task &&
            material.old_governing_task.length > 0
              ? material.old_governing_task[0]
              : null;
          isGoverning =
            oldGoverningActivity &&
            oldGoverningActivity.activity_id === impactedActivityId;
        } else {
          // new_governing_task has always zero or max one element
          const newGoverningActivity =
            material.new_governing_task &&
            material.new_governing_task.length > 0
              ? material.new_governing_task[0]
              : null;
          isGoverning =
            newGoverningActivity &&
            newGoverningActivity.activity_id === impactedActivityId;
        }

        if (isGoverning) break;
      }
    }
    return isGoverning;
  };

  /**
   * Method to check an activity is governiong for the given submittal or not
   * @param submittal
   * @param impactedActivityId
   * @param isActivityDeleted
   * @returns
   */
  const isActivityGoverningForSubmittal = (
    submittal: any,
    impactedActivityId: any,
    isActivityDeleted: boolean = false
  ) => {
    let isGoverning = false;
    if (isActivityDeleted) {
      // old_governing_task has always zero or max one element
      const oldGoverningActivity =
        submittal.old_governing_task && submittal.old_governing_task.length > 0
          ? submittal.old_governing_task[0]
          : null;
      isGoverning =
        oldGoverningActivity &&
        oldGoverningActivity.activity_id === impactedActivityId;
    } else {
      // new_governing_task has always zero or max one element
      const newGoverningActivity =
        submittal?.new_governing_task &&
        submittal?.new_governing_task?.length > 0
          ? submittal?.new_governing_task[0]
          : null;

      isGoverning =
        newGoverningActivity &&
        newGoverningActivity.activity_id === impactedActivityId;
    }

    if (!isGoverning) {
      // Check inside the linked materials
      const newGoverningMaterial =
        submittal?.new_governing_material &&
        submittal?.new_governing_material?.length > 0
          ? submittal?.new_governing_material[0]
          : null;

      // If newGoverningMaterial exist the find the linked activity and then compare with the activity
      if (newGoverningMaterial) {
        const allMaterialDateBlock = scheduleImpact?.all_material_date_block;
        const material =
          allMaterialDateBlock && allMaterialDateBlock[newGoverningMaterial.id];
        if (isActivityDeleted) {
          isGoverning = isActivityGoverningForMaterial(
            [material],
            impactedActivityId,
            isActivityDeleted
          );
        } else {
          isGoverning = isActivityGoverningForMaterial(
            [material],
            impactedActivityId
          );
        }
      }
    }

    return isGoverning;
  };

  const isActivityGoverningForSubmittals = (
    submittals: any,
    impactedActivityId: any,
    isActivityDeleted: boolean = false
  ) => {
    let isGoverning = false;
    if (impactedActivityId && submittals && submittals.length > 0) {
      for (let i: number = 0, len = submittals.length; i < len; i += 1) {
        const submittal = submittals[i];

        isGoverning = isActivityGoverningForSubmittal(
          submittal,
          impactedActivityId,
          isActivityDeleted
        );
      }
    }

    return isGoverning === true;
  };

  const isActivityGoverning = (impactedActivity: any) => {
    const impactedActivityId = impactedActivity.activity_id;
    return (
      isActivityGoverningForMaterial(
        impactedActivity.linked_materials,
        impactedActivityId,
        impactedActivity.deleted
      ) ||
      isActivityGoverningForSubmittals(
        impactedActivity.linked_submittals,
        impactedActivityId,
        impactedActivity.deleted
      )
    );
  };

  const getStructureSubmittalLinkedObject = (sdb: any, submittalId: any) => {
    let newGoverning = sdb?.new_governing_task;
    if (sdb?.new_governing_material && sdb?.new_governing_material.length > 0) {
      const mat = sdb.new_governing_material[0];
      const governing = {
        activity_id: mat.implicit ? "N/A" : mat.material_sequence_id,
        id: mat.id,
        name: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name,
        start_date: mat.start_date
      };
      newGoverning = [governing];
    }

    let oldGoverning = sdb?.old_governing_task || "";
    if (sdb?.old_governing_material && sdb?.old_governing_material.length > 0) {
      const mat = sdb?.old_governing_material[0];
      const governing = {
        activity_id: mat.implicit ? "N/A" : mat.material_sequence_id,
        id: mat.material_id,
        name: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name,
        start_date: mat.start_date,
        title: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name
      };
      oldGoverning = [governing];
    }

    return {
      SDB: { new: sdb?.new, old: sdb?.old },
      action: sdb?.action || "No action needed",
      new_governing_task: newGoverning,
      old_governing_task: oldGoverning,
      submittal_id: submittalId,
      submittalId: submittalId.submittal_id
    };
  };

  /**
   * Method to create and add a missing linked submittal in the activity's linked_submittal.
   * @param sdb
   * @param submittalId
   * @returns
   */
  const getStructureMissingSubmittalLinkedObject = (
    sdb: any,
    submittalId: any
  ) => {
    let formattedSubmittal = null;
    if (sdb) {
      formattedSubmittal = {
        SDB: { new: sdb?.new, old: sdb?.old },
        action: sdb?.action || "No action needed",
        new_governing_material: sdb.new_governing_material,
        old_governing_material: sdb.old_governing_material,
        new_governing_task: sdb.new_governing_task,
        old_governing_task: sdb.old_governing_task,
        new_self_governing: sdb.new_self_governing,
        old_slef_governing: sdb.old_self_governing,
        submittal_id: submittalId,
        submittalId: submittalId.submittal_id,
        workflow_status: sdb.workflow_status
      };
    }
    return formattedSubmittal;
  };

  useEffect(() => {
    const resultArr: any[] = [];
    const allSubmittalDateBlock = scheduleImpact?.all_submittal_date_block;
    const allMaterialDateBlock = scheduleImpact?.all_material_date_block;
    const scheduleImpacts = scheduleImpact.schedule_impact?.sort(
      (a: any, b: any) => a.activity_id - b.activity_id
    );

    scheduleImpacts?.forEach((impact: any) => {
      const task = {
        activity_id: impact.activity_id,
        deleted: impact.deleted,
        status: impact.deleted ? "deleted" : "updated",
        delta: "",
        task_id: impact.task_id,
        linked_submittals: [] as Array<any>,
        linked_materials: [] as Array<any>,
        new_start: undefined,
        new_end_date: undefined,
        old_start: undefined,
        old_end_date: undefined,
        text: "",
        allSubmittalDateBlock: []
      };

      const isGoverningTask = isActivityGoverning(impact);
      /**
       * If the current activity is governing for any linked submittal and material
       * then show it other wise don't show in grid.
       */
      if (!isGoverningTask) {
        return;
      }

      if (impact.deleted) {
        const activity = scheduleComparison?.deleted[task.activity_id];
        task.old_start = activity?.start_date;
        task.old_end_date = activity?.old_end_date;
        task.text = activity?.text;
        if (activity) {
          task.linked_submittals = impact?.linked_submittals
            ? impact?.linked_submittals.map((linkedSubmittal: any) => {
                return getStructureSubmittalLinkedObject(
                  allSubmittalDateBlock[linkedSubmittal.submittal_id],
                  linkedSubmittal
                );
              })
            : [];
          if (Object.keys(allMaterialDateBlock).length > 0)
            task.linked_materials = impact?.linked_materials
              ? impact?.linked_materials.map((linkedMaterial: any) => {
                  return getStructureMaterialLinkedObject(
                    linkedMaterial,
                    linkedMaterial?.material_id
                  );
                })
              : [];
        }

        task.allSubmittalDateBlock = allSubmittalDateBlock;
      } else {
        const activity = scheduleComparison?.updated[task.activity_id];
        task.new_start = activity?.new_start;
        task.old_start = activity?.old_start;
        task.new_end_date = activity?.new_end_date;
        task.old_end_date = activity?.old_end_date;
        task.text = activity?.text;

        if (task?.old_start || task?.new_start) {
          const oldStartDate = DateUtils.dateTimeObj(new Date(task.old_start!));
          const newStartDate = DateUtils.dateTimeObj(new Date(task.new_start!));
          if (oldStartDate.diff(newStartDate) < 0) {
            task.delta = `+ ${activity?.delta}`;
          } else {
            task.delta = `- ${activity?.delta}`;
          }
        }
        if (activity) {
          task.linked_submittals = [...impact.linked_submittals];
          task.linked_materials = [...impact.linked_materials];
        }

        task.allSubmittalDateBlock = allSubmittalDateBlock;
      }

      // if submittal is not in task linked_submittal but it is in
      // linked_material's material -> linked_submittal then add it is task linked submittal
      // - It is missed in data from server
      task.linked_materials.forEach((mat: any) => {
        mat.linked_submittals?.forEach((id: any) => {
          if (!task.linked_submittals.some((s: any) => s.submittal_id === id)) {
            const sdb = allSubmittalDateBlock[id];
            // TODO - need to verify this new structure with UI view
            if (sdb) {
              const newSubmittal = getStructureMissingSubmittalLinkedObject(
                sdb,
                id
              );
              task.linked_submittals.push(newSubmittal);
            }
          }
        });
      });

      task.linked_submittals = task.linked_submittals.filter((submittal) => {
        return allSubmittalDateBlock[
          submittal.submittalId || submittal.submittal_id // in case of deletion
        ];
      });

      task.linked_materials = task.linked_materials.filter(
        (material) => allMaterialDateBlock[material.material_id]
      );

      if (
        task.linked_submittals?.length > 0 ||
        task.linked_materials?.length > 0
      ) {
        resultArr.push(task);
      }
    });
    setTaskData(resultArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleComparison, scheduleImpact]);

  /**
   * Remove the duplicate submittals from the linked submittals
   * @param linkedSubmittals
   * @returns
   */
  const removeDuplicateSubmittals = (linkedSubmittals: any) => {
    let submittals: any = [];
    if (linkedSubmittals.length > 0) {
      const uniqueSubmittals: any = {};

      linkedSubmittals.forEach((s: any) => {
        if (!uniqueSubmittals[s.submittal_id]) {
          uniqueSubmittals[s.submittal_id] = s;
        }
      });

      submittals = Object.values(uniqueSubmittals);
    }

    return submittals;
  };

  /**
   * Clear the submittals which has no impact because there is no activity linked directly or through material
   * It is self governing
   * @param linkedSubmittals
   * @returns
   */
  const removeSubmittalForNonGoveringActivity = (linkedSubmittals: any) => {
    const submittals: any = [];
    if (linkedSubmittals.length > 0) {
      linkedSubmittals.forEach((submittal: any) => {
        if (
          !(
            submittal.new_governing_task &&
            submittal.new_governing_task.length === 0 &&
            submittal.new_governing_material &&
            submittal.new_governing_material.length === 0
          )
        ) {
          submittals.push(submittal);
        }
      });
    }
    return submittals;
  };

  /**
   * Clear the linked submittals which has no governing dependency of current impacted activity.
   * There some submittals which are coming incorrectly.
   * @param linkedSubmittals
   * @param impactedActivityId
   */
  const removeSubmittalsWhichIsNotLinkedWithCurrentImpactedActivity = (
    linkedSubmittals: any,
    impactedActivityId: any,
    isActivityDeleted: boolean
  ) => {
    const submittals: any = [];
    if (linkedSubmittals.length > 0) {
      linkedSubmittals.forEach((submittal: any) => {
        if (
          isActivityGoverningForSubmittal(
            submittal,
            impactedActivityId,
            isActivityDeleted
          )
        ) {
          submittals.push(submittal);
        }
      });
    }

    return submittals;
  };

  const removeMaterialsWhichIsNotLinkedWithCurrentImpactedActivity = (
    linkedMaterials: any,
    impactedActivityId: any,
    isActivityDeleted: boolean
  ) => {
    const materials: any = [];
    if (linkedMaterials.length > 0) {
      linkedMaterials.forEach((material: any) => {
        if (
          isActivityGoverningForMaterial(
            [material],
            impactedActivityId,
            isActivityDeleted
          )
        ) {
          materials.push(material);
        }
      });
    }
    return materials;
  };

  const removeMaterialForNonGoveringActivity = (linkedMaterials: any) => {
    const materials: any = [];
    if (linkedMaterials.length > 0) {
      linkedMaterials.forEach((material: any) => {
        if (
          !(
            material.new_governing_task &&
            material.new_governing_task.length === 0 &&
            material.old_governing_task &&
            material.old_governing_task.length === 0
          )
        ) {
          materials.push(material);
        }
      });
    }
    return materials;
  };

  const detailCellRenderer = (params: any) => {
    const impactedActivityId = params?.data?.activity_id;
    const isDeleted = params?.data?.deleted;

    const linkedSubmittals = removeSubmittalForNonGoveringActivity(
      removeDuplicateSubmittals(
        removeSubmittalsWhichIsNotLinkedWithCurrentImpactedActivity(
          params?.data?.linked_submittals,
          impactedActivityId,
          isDeleted
        )
      )
    );

    const linkedMaterials = removeMaterialForNonGoveringActivity(
      removeMaterialsWhichIsNotLinkedWithCurrentImpactedActivity(
        params?.data?.linked_materials,
        impactedActivityId,
        isDeleted
      )
    );

    return (
      <>
        {linkedSubmittals?.length === 0 && linkedMaterials?.length === 0 && (
          <div className="flex h-full w-full items-center px-4">
            No materials or submittals are affected due to this change in
            schedule.
          </div>
        )}
        {linkedSubmittals?.length > 0 && linkedMaterials?.length === 0 && (
          <div className="w-full pb-2 px-5">
            <SubmittalScheduleImpact
              linkedSubmittals={linkedSubmittals}
              isDeleted={params.data.status === "deleted"}
            />
          </div>
        )}
        {linkedSubmittals?.length === 0 && linkedMaterials?.length > 0 && (
          <div className="w-full pb-2 px-5">
            <MaterialScheduleImpact
              linkedMaterials={linkedMaterials}
              allSubmittalDateBlock={params.data.allSubmittalDateBlock}
              isDeleted={params.data.status === "deleted"}
            />
          </div>
        )}

        {linkedSubmittals?.length > 0 && linkedMaterials?.length > 0 && (
          <div>
            <div
              className="w-full pb-2 px-5"
              style={{ marginBottom: 1.5 * padding }}
            >
              <SubmittalScheduleImpact
                linkedSubmittals={linkedSubmittals}
                isDeleted={params.data.status === "deleted"}
              />
            </div>
            <div className="w-full p-2 px-5">
              <MaterialScheduleImpact
                linkedMaterials={linkedMaterials}
                allSubmittalDateBlock={params.data.allSubmittalDateBlock}
                isDeleted={params.data.status === "deleted"}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const columnDefs: any = useMemo(() => {
    return [
      {
        headerName: "Activity ID",
        headerTooltip: "ACTIVITY ID",
        tooltipField: "activity_id",
        colId: "activity_id",
        field: "activity_id",
        width: 200,
        sort: "asc",
        cellRenderer: "agGroupCellRenderer"
      },
      {
        headerName: "Activity Name",
        headerTooltip: "ACTIVITY NAME",
        tooltipField: "text",
        colId: "text",
        field: "text"
      },
      {
        headerName: "Start Date",
        headerTooltip: "START DATE",
        field: "old_start",
        width: 80,
        cellRenderer: ({ data }: any) => {
          if (data.old_start && data.new_start)
            return cellWithDataDifference(
              DateUtils.format(data.new_start),
              DateUtils.format(data.old_start)
            );
          if (data.old_start) return DateUtils.format(data.old_start);
          return "";
        },
        comparator: DateFilter.comparator
      },
      {
        headerName: "End Date",
        headerTooltip: "END DATE",
        field: "new_end_date",
        width: 80,
        cellRenderer: ({ data }: any) => {
          if (data.old_end_date && data.new_end_date)
            return cellWithDataDifference(
              DateUtils.format(data.new_end_date),
              DateUtils.format(data.old_end_date)
            );
          if (data.old_end_date) return DateUtils.format(data.old_end_date);
          return "";
        },
        comparator: DateFilter.comparator
      },
      {
        headerName: "Delta (Working Days)",
        headerTooltip: "DELTA (WORKING DAYS)",
        colId: "delta",
        field: "delta",
        width: 100,
        valueGetter: ({ data }: any) => {
          if (data?.status === "deleted") {
            return "This task has been removed.";
          }
          return data?.delta;
        },
        tooltipValueGetter: ({ data }: any) => {
          if (data?.status === "deleted") {
            return "This task has been removed.";
          }
          return data?.delta;
        }
      }
    ];
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      autoHeight: true,
      menuTabs: []
    };
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.forEachNode((node: any) => {
      node.setExpanded(node?.rowIndex === 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailRowHeight = (params: any) => {
    const isDetailRow = params.node.detail;
    if (!isDetailRow) {
      return undefined;
    }

    const submittalCount = params.data?.linked_submittals?.length ?? 0;
    const minSubmittalCount = Math.min(submittalCount, maxRowCount);
    const materialCount = params.data?.linked_materials?.length ?? 0;
    const minMaterialCount = Math.min(materialCount, maxRowCount);

    const heightOfSubmittal: number = minSubmittalCount
      ? minSubmittalCount * rowHeight + titleHeight + headerHeight
      : 0;
    const heightOfMaterial: number = minMaterialCount
      ? minMaterialCount * rowHeight + titleHeight + headerHeight
      : 0;

    const additionalPadding =
      minSubmittalCount > 0 && minMaterialCount > 0 ? padding : 0;

    return (
      heightOfSubmittal + heightOfMaterial + 2 * padding + additionalPadding
    );
  };

  const isRowMaster = (params: any) => {
    return (
      params.linked_submittals?.length > 0 || params.linked_materials?.length
    );
  };

  return (
    <div className="w-full h-full">
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine submittal-schedule-page"
      >
        <AgGridReact
          ref={gridRef}
          rowData={taskData!}
          onGridReady={() => {
            // setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail
          detailCellRenderer={detailCellRenderer}
          onFirstDataRendered={onFirstDataRendered}
          getRowHeight={detailRowHeight}
          isRowMaster={isRowMaster}
          suppressDragLeaveHidesColumns
          suppressMovableColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
        />
      </div>
    </div>
  );
}

export default GridCompareSchedulePage;
