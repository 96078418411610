import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import { useContext, useEffect, useRef, useState } from "react";
import { DATE_FORMAT_YYYYMMDD, DateFilter, DateUtils } from "utils/dateutils";
import { ProjectContext } from "context/ProjectProvider";
import { getScheduleLookupReport } from "services/auth";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { TActivityTask } from "./models";
import { TDateRangeActivity } from "../components/activities-filter-form";
import { DetailCellRendererActivity } from "./detail-cell-renderer";

function ScheduleActivitiesReportComponent({
  openFilter,
  reportFilter
}: {
  openFilter: () => void;
  reportFilter: TDateRangeActivity | undefined;
}) {
  const gridRef = useRef<AgGridReact<TActivityTask>>(null);
  const { tokenRetrievalState } = useContext(ProjectContext);
  const [activities, setActivities] = useState<Array<TActivityTask>>();

  useEffect(() => {
    const updateReportData = async () => {
      const response = await getScheduleLookupReport(
        tokenRetrievalState.token,
        {
          start_date: reportFilter?.startDate
            ? DateUtils.format(reportFilter?.startDate, DATE_FORMAT_YYYYMMDD)
            : null,
          end_date: reportFilter?.endDate
            ? DateUtils.format(reportFilter?.endDate, DATE_FORMAT_YYYYMMDD)
            : null
        }
      );
      if (response.data && response.data.success) {
        setActivities(response.data.success);
      } else {
        console.log(response);
        setActivities([]);
      }
    };
    updateReportData();
  }, [
    reportFilter?.endDate,
    reportFilter?.startDate,
    tokenRetrievalState.token
  ]);

  const columnDefs = [
    {
      colId: "source_task_id",
      field: "source_task_id",
      headerName: "ACTIVITY ID",
      headerTooltip: "ACTIVITY ID",
      cellRenderer: "agGroupCellRenderer",
      tooltipField: "source_task_id"
    },
    {
      colId: "text",
      field: "text",
      headerName: "ACTIVITY NAME",
      headerTooltip: "ACTIVITY NAME",
      tooltipField: "text"
    },
    {
      colId: "start_date",
      field: "start_date",
      headerName: "START DATE",
      headerTooltip: "START DATE",
      comparator: DateFilter.comparator,
      valueGetter: ({ data }) =>
        data?.start_date ? DateUtils.format(data.start_date) : "",
      tooltipValueGetter: ({ value }) => value,
      sort: "asc"
    },
    {
      colId: "end_date",
      field: "end_date",
      headerName: "END DATE",
      headerTooltip: "END DATE",
      comparator: DateFilter.comparator,
      valueGetter: ({ data }) =>
        data?.end_date ? DateUtils.format(data.end_date) : "",
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "duration",
      field: "duration",
      headerName: "DURATION",
      headerTooltip: "DURATION",
      valueGetter: ({ data }) => {
        if (data?.duration) {
          return data.duration / 8;
        }
        return "";
      },
      tooltipValueGetter: ({ value }) => value
    }
  ] as (ColDef<TActivityTask> | ColGroupDef<TActivityTask>)[];

  const headerItems = [
    <CiqAgSearch
      key="ScheduleActivitiesReportComponent_search"
      gridRef={gridRef}
      placeholder="Search Activities"
    />,
    <Button key="ScheduleActivitiesReportComponentt_new" onClick={openFilter}>
      Run New Report
    </Button>,
    <Button key="ScheduleActivitiesReportComponent_exportCSV">
      Export CSV
    </Button>
  ];
  return (
    <CiqGridViewWrapper
      headerInfo={{
        entityName: "SCHEDULE LOOKAHEAD REPORT",
        gridRef,
        items: headerItems
      }}
      gridInfo={{
        gridRef,
        columnDefs,
        rowData: activities,
        masterDetail: true,
        detailCellRenderer: DetailCellRendererActivity,
        detailRowAutoHeight: true,
        sideBar: null,
        suppressMovableColumns: true
      }}
    />
  );
}
export default ScheduleActivitiesReportComponent;
