import { AgGridReact } from "ag-grid-react";
import { TCiqAgGrid } from "components/ciq-grid-view-wrapper/model";
import { GridLoadingIndicator } from "components/widgets";
import { useEffect, useState } from "react";

function CiqAgGrid<TData = any>(props: TCiqAgGrid) {
  const { gridRef: ref, rowData } = props;
  const [isGridReady, setGridReady] = useState(false);

  const gridOptions = {
    ref,
    headerHeight: 36,
    defaultColDef: {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: [],
      flex: 1
    },
    overlayNoRowsTemplate: "No Data Found",
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true
          }
        }
      ]
    },
    autoGroupColumnDef: {
      headerName: "",
      field: "",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true
      }
    },
    loadingOverlayComponent: GridLoadingIndicator,
    suppressRowClickSelection: true,
    suppressAggFuncInHeader: true,
    suppressDragLeaveHidesColumns: true,
    suppressContextMenu: true,
    suppressClickEdit: true,
    cacheQuickFilter: true,
    maintainColumnOrder: true,
    readOnlyEdit: true,
    enableCellTextSelection: true,
    onGridReady: () => {
      setGridReady(true);
    },
    ...props
  };

  useEffect(() => {
    if (ref.current && isGridReady && rowData?.length === 0) {
      const { api } = ref.current;
      if (api) {
        api.hideOverlay();
        api.showNoRowsOverlay();
      }
    }
  }, [rowData, isGridReady, ref]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && ref && ref.current) {
        ref.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [ref, isGridReady]);

  return (
    <div className="grow ag-theme-alpine px-2 relative">
      <AgGridReact<TData> {...gridOptions} />
    </div>
  );
}
export default CiqAgGrid;
