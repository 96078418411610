import { NotificationPlacement } from "antd/lib/notification";
import { Link } from "react-router-dom";

export const newEntityNotificationMessage = (props: {
  projectId: string;
  id: string;
  title: string;
  featureKey: string;
  placement?: NotificationPlacement;
}) => {
  const { projectId, id, title, placement = "top", featureKey } = props;
  return {
    message: (
      <div className="notifications space-x-1">
        <span className="notification-highlight-title ">{title}</span>
        <span className="notification-title">successfully created.</span>
      </div>
    ),
    description: (
      <div className="notifications space-x-1">
        <span className="notification-subTitle">Click</span>
        <Link
          className="underline notification-highlight-subTitle "
          to={{
            pathname: `/project/${projectId}/${featureKey}/${id}`
          }}
        >
          here
        </Link>
        <span className="notification-subTitle">to view details.</span>
      </div>
    ),
    placement
  };
};
