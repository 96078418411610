export const BID_PACKAGE_LIST_VIEW = `BidPackageListQuery {
  bid_package_list_view {
    id
    auto_inc_value
    number
    title
    description
    wave
    bid_scope
    workflow_status
    next_planned_deadline
    due_date
    risk
    float
    awarded_subcontractor_name
    awarded_value
    estimated_value
    preconstruction_lead_first_name
    preconstruction_lead_id
    preconstruction_lead_last_name
    operations_lead_first_name
    operations_lead_id
    operations_lead_last_name
    bid_package_template_name
    no_of_bidders
    bidding_subcontractors
  }
}`;
