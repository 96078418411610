import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useState } from "react";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";
import { Button, Divider, Select } from "antd";

function SelectFeatureSpecSections(props: {
  onDeselectAction?: any;
  onSelectAction?: any;
  onCreateSpecSectionClick?: any;
}) {
  const { onDeselectAction, onSelectAction, onCreateSpecSectionClick } = props;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);
  const [open, setOpen] = useState(false);

  const { data: specSections, refetch: refetchSpecs } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  useEffect(() => {
    const hasInsertEvent = eventLogs.some(
      (log) =>
        log.data_source === "spec_sections" && log.change_type === "insert"
    );
    if (hasInsertEvent) {
      refetchSpecs();
    }
  }, [eventLogs, refetchSpecs]);

  return (
    <Select
      open={open}
      showSearch
      mode="multiple"
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      options={specSections?.spec_sections.map((spec) => ({
        value: spec.id,
        label: spec.title
          ? `${spec.section_number} - ${spec.title}`
          : spec.section_number
      }))}
      onSelect={onSelectAction}
      onDeselect={onDeselectAction}
      showArrow
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu: any) => {
        return (
          <>
            {menu}
            <Divider className="my-1" style={{ margin: "8px 0" }} />
            <div className="w-full px-1">
              <Button
                className="rounded-none  bg-[#3b3b3b3b] hover:bg-[#3b3b3b5c] w-full"
                onClick={() => {
                  setOpen(false);
                  if (onCreateSpecSectionClick) {
                    onCreateSpecSectionClick(true);
                  }
                }}
              >
                Add New Spec Section
              </Button>
            </div>
          </>
        );
      }}
    />
  );
}

export default SelectFeatureSpecSections;
