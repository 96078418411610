import { IconSearch } from "@tabler/icons-react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Input } from "antd";
import {
  MaterialIdLinkCellRenderer,
  specSectionRenderer,
  userOrgCellRenderer
} from "components/cell-renders";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { QUERY_MATERIAL_RISK_REPORT } from "services/graphQL/queries";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { getMaterialFormattedValues } from "pages/reports-menu/upcoming-workflows/export-csv-util";
import { RiskStates } from "../../constants";
import { useCiqMaterialRiskReportList } from "./hooks/risk-report";

type Props = {
  risk: RiskStates;
  isIntegrationMode: boolean;
};

function MaterialRiskDetail(props: Props) {
  const { risk, isIntegrationMode } = props;

  const { projectId } = useParams() as any;

  const gridRef = useRef<AgGridReact<any>>(null);

  const [gridSearchtext, setGridSearchtext] = useState("");

  const {
    gqlClientForProject,
    projectDetails,
    eventLogs,
    columnHeaders: { materialHeaderMap }
  }: TProjectContext = useContext(ProjectContext);

  const { projectParticipants: WFProjectParticipants } =
    useProjectParticipants(isIntegrationMode);

  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const [gridData, setGridData] = useState<{
    materials?: any;
  }>({
    materials: null
  });

  const { data: materialRiskData } = useCiqMaterialRiskReportList({
    gqlClientForProject,
    variables: {
      where: { risk_level: { _eq: risk } }
    }
  });

  const [getLazyMaterialRiskData] = useLazyQuery(QUERY_MATERIAL_RISK_REPORT, {
    client: gqlClientForProject,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (materialRiskData) {
      setGridData({
        materials: materialRiskData
          ? materialRiskData?.material_cumulative_risk_report_func
          : []
      });
    }
  }, [materialRiskData]);

  const deleteMaterialsFromList = useCallback(
    (ids: Array<string>) => {
      const items = gridData.materials ? [...gridData.materials] : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setGridData({
        materials: items
      });
    },
    [gridData.materials]
  );

  const getAndUpdateMaterialList = useCallback(
    async (materialIds: Array<string>) => {
      if (!materialIds.length) return;
      const variables =
        materialIds.length > 10 ? {} : { where: { id: { _in: materialIds } } };

      const res = await getLazyMaterialRiskData({
        variables,
        fetchPolicy: "network-only"
      });
      if (res.data) {
        const updatedmaterialList =
          res.data.material_cumulative_risk_report_func;

        if (updatedmaterialList.length === 0) {
          deleteMaterialsFromList(materialIds);
          return;
        }

        const items = [...gridData.materials] as Array<any>;
        updatedmaterialList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setGridData({
          materials: items
        });
      }
    },
    [deleteMaterialsFromList, getLazyMaterialRiskData, gridData.materials]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const conditionFormaterialRiskLog = (log: TAuditChangeLog) =>
        // log.info.computation === true &&
        log?.info?.material_ids && log?.info?.material_ids?.length > 0;

      if (eventLogs.some(conditionFormaterialRiskLog)) {
        const auditLogs = eventLogs.filter(
          (log: TAuditChangeLog) => log.info.material_ids
        ) as Array<TAuditChangeLog>;

        const deletedIds = auditLogs
          .filter(
            (log) =>
              log.change_type === "delete" && log.data_source === "materials"
          )
          .map((s: TAuditChangeLog) => s.info.material_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteMaterialsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter(
            (log) =>
              log.change_type === "update" &&
              (log.data_source === "date_block" ||
                log.data_source === "material")
          ) // update create import
          .map((s) => s.info.material_ids) as any;

        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateMaterialList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, deleteMaterialsFromList, getAndUpdateMaterialList]);

  const customComparator = (valueA: any, valueB: any) => {
    return (valueA || "")
      .toLowerCase()
      .localeCompare((valueB || "").toLowerCase());
  };

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: false,
      flex: 1
    };
  }, []);
  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        colId: "material_id",
        field: "material_id",
        headerName: materialHeaderMap?.material_id?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.material_id?.toUpperCase() || "",
        tooltipField: "material_id",
        filter: false,
        menuTabs: [],
        cellRenderer: MaterialIdLinkCellRenderer
      },
      {
        colId: "custom_spec_section",
        field: "custom_spec_section",
        headerName: materialHeaderMap?.spec_section_no?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.spec_section_no?.toUpperCase() || "",
        filter: "agSetColumnFilter",
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        sortable: true,
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              return params.value.split("-")[0].trim();
            } catch {
              return params.value;
            }
          }
        },
        cellRenderer: specSectionRenderer,
        cellRendererParams: {
          specNoField: "spec_section_no",
          specNameField: "spec_section_name"
        },
        valueGetter: (params: any) => {
          try {
            let specSectionStr = params.data.spec_section_no || "";
            if (params.data.spec_section_name) {
              specSectionStr += ` - ${params.data.spec_section_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        },
        comparator: customComparator,
        tooltipValueGetter: (params) => params.value
      },
      {
        field: "name",
        colId: "name",
        headerName: materialHeaderMap?.name?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.name?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        valueGetter: (params: any) => {
          const { data } = params;
          if (
            !data.material_id &&
            data.material_submitttal_links &&
            data.material_submitttal_links.length
          ) {
            const submittalId = data.material_submitttal_links[0].submittal_id;
            const specNo = data.spec_section_no;

            let materialName = "Material for Submittal ";
            if (specNo) {
              materialName += `${specNo} - `;
            }
            if (submittalId) {
              materialName += `${submittalId}`;
            }
            return materialName;
          }

          return data.name;
        },
        tooltipValueGetter: (params) => params.value
      },
      {
        field: "material_tag",
        colId: "material_tag",
        tooltipField: "material_tag",
        headerName: materialHeaderMap?.tag_name?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.tag_name?.toUpperCase() || "",
        menuTabs: []
      },
      {
        field: "trade_partner_name",
        colId: "trade_partner",
        headerName: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        tooltipField: "trade_partner_name",
        sort: "asc"
      },
      {
        colId: "assignee",
        headerName: materialHeaderMap?.assignee?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.assignee?.toUpperCase() || "",
        cellRenderer: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "assignee",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name",
            statusKey: "assignee_status"
          }
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        field: "status",
        colId: "status",
        headerName: materialHeaderMap?.status?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.status?.toUpperCase() || "",
        valueGetter: ({ data }) => {
          if (
            data?.date_block_materials?.length > 0 &&
            data?.date_block_materials[0]?.milestone_state
          ) {
            return data?.date_block_materials[0]?.milestone_state || "";
          }
          return "";
        },
        tooltipValueGetter: (params) => params.value,
        menuTabs: ["filterMenuTab"],
        filter: true
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: "RISK",
        hide: true
      },
      {
        colId: "risk_assessment",
        headerName: "RISK ASSESSMENT",
        hide: true
      },
      {
        colId: "linked_submittals",
        field: "material_submitttal_links",
        headerName: "LINKED SUBMITTALS",
        hide: true
      },
      {
        colId: "linked_tasks",
        field: "material_schedule_links",
        headerName: "IMPACTED ACTIVITIES",
        hide: true
      }
    ];
  }, [materialHeaderMap]);

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(gridSearchtext);
  }, [gridSearchtext]);

  useEffect(() => {
    if (materialRiskData) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: ["assignee"]
        });
      }, 0);
    }
  }, [materialRiskData]);

  const gridContext: any = useMemo(() => {
    return {
      projectId,
      isIntegrationMode,
      projectParticipants
    };
  }, [isIntegrationMode, projectParticipants, projectId]);

  const exportLogAsCSV = () => {
    const additionalColumns = [
      "risk_level",
      "risk_assessment",
      "linked_submittals",
      "linked_tasks"
    ];
    const columnApi = gridRef.current?.columnApi;
    if (gridRef.current && columnApi)
      gridRef.current?.api?.exportDataAsCsv({
        columnKeys: columnApi
          .getAllGridColumns()
          ?.filter((col) => {
            const colId = col.getColId();
            return col.isVisible() || additionalColumns?.includes(colId);
          })
          .map((col) => col.getColId()),
        processCellCallback(params: any): string {
          return getMaterialFormattedValues(params);
        },
        fileName: `Materials at ${risk} Risk_${projectDetails?.name}`
      });
  };

  return (
    <div className="pl-0.5">
      <div className="mt-3 flex items-end justify-between">
        <div className="uppercase font-semibold">MATERIALS AT {risk} RISK</div>
        <div className="flex items-center gap-x-3">
          <div className="w-[225px] py-1">
            <Input
              type="text"
              placeholder="Search Materials"
              prefix={<IconSearch size={14} />}
              onChange={(event) => {
                setGridSearchtext(event.target.value);
              }}
              allowClear
              disabled={!gridData.materials || !gridData.materials.length}
            />
          </div>
          <Button
            disabled={!gridData.materials || !gridData.materials.length}
            className="w-[110px] hide-on-print"
            onClick={exportLogAsCSV}
          >
            Export CSV
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <div style={{ height: "calc(100vh - 325px)" }}>
          <AgGridReact
            ref={gridRef}
            className="ag-theme-alpine"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={gridData.materials}
            headerHeight={36}
            rowHeight={62}
            pagination={false}
            suppressPaginationPanel
            suppressContextMenu
            context={gridContext}
          />
        </div>
      </div>
    </div>
  );
}

export default MaterialRiskDetail;
