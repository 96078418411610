import { ReactNode, useMemo, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LicenseManager } from "ag-grid-enterprise";
import {
  Login,
  HomePage,
  ProjectsListpage,
  // ProjectCreatePage,
  SubmittalLogPage,
  SubmittalDetailspage,
  MaterialListpage,
  Users,
  NotFoundPage,
  MaterialDetailsPage,
  ProjectDashboardPage,
  RiskReportPage,
  DesignPage
} from "pages";
import PrivateRoute from "ProtectedRoute";
import ForgetPassword from "pages/forgetpassword";
import SetupAccount from "pages/setup_account";
import SetPassword from "pages/setpassword";
import ChangePassword from "pages/changepassword";
import CompanyList from "pages/companies";
import ProjectProvider from "context/ProjectProvider";
import SubscriptionProvider from "context/SubscriptionProvider";
import AcceptInvitePage from "pages/accept-invite/acceptInvite";
import CreateProjectPage from "pages/project-create/create-project";
import ScheduleMenuTabs from "pages/schedule-manu-tabs/schedule-manu-tabs";
import { Navbar } from "components";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "./antd-override.scss";

import { AppContext } from "context/AppProvider";
import { useGetGQLClientForSubscription } from "hooks/init-gql-client";
import "./assets/scss/index.scss";
import PartnerApp from "partner-app/Partner-App";
import { AG_GRID_LICENSE } from "config/config";
import TinyUrlRedirection from "pages/tiny-url";
import SubscriptionSettingsPage from "pages/subscription-settings";
import { updateGAConfigWithUserId } from "utils/analytics";
import ProjectSettingManu from "pages/project-setting-menu";
import AdminApp from "admin-app/admin-app";
import { setAccessTokenAndSubscriptionId } from "services/auth";
import RiskReportExportPDF from "pages/risk-report/risk-report-export-pdf";
import ReportsPageManu from "pages/reports-menu";
import UpcomingSubmittals from "pages/reports-menu/upcoming-workflows";

import PreconstructionDetailsPage from "pages/preconstruction-details";
import ScheduleActivitiesReport from "pages/reports-menu/schedule-activities-report";
import PreconstructionLogPage from "pages/preconstruction-log-page";
import DesignPackageLogPage from "pages/design-package-log-page";
import DesignPackageDetailPage from "pages/design-package-details-page";
import { PartnerBaseRoute } from "./constants";

if (AG_GRID_LICENSE) LicenseManager.setLicenseKey(AG_GRID_LICENSE);

// TODO: make routing config for better route management
type LayoutProps = {
  children: ReactNode;
};

function NoMenuLayout(props: LayoutProps) {
  const { children } = props;
  return (
    <div className="flex flex-col">
      <Navbar />
      {children}
    </div>
  );
}

function HomeLayout(props: LayoutProps) {
  const { children } = props;
  const menuLinks = useMemo(
    () => [
      {
        label: "Users",
        key: "/users"
      },
      {
        label: "Companies",
        key: "/companies"
      },
      {
        label: "Projects",
        key: "/projects"
      },
      {
        label: "Settings",
        key: "/settings"
      }
    ],
    []
  );

  return (
    <div className="flex flex-col h-screen">
      <Navbar menuLinks={menuLinks} />
      {children}
    </div>
  );
}

function ProjectLayout(props: LayoutProps) {
  const { children } = props;
  const { projectId } = useParams<{ projectId: string }>();

  const menuLinks = useMemo(
    () => [
      {
        label: "Design",
        key: `/project/${projectId}/design-packages`
      },
      {
        label: "Preconstruction",
        key: `/project/${projectId}/bid-packages`
      },
      {
        label: "Submittals",
        key: `/project/${projectId}/submittals`
      },
      {
        label: "Materials",
        key: `/project/${projectId}/materials`
      },
      {
        label: "Schedules",
        key: `/project/${projectId}/schedule`
      },
      {
        label: "Reports",
        key: `/project/${projectId}/reports`
      },
      {
        label: "Settings",
        key: `/project/${projectId}/settings`
      }
    ],
    [projectId]
  );

  return (
    <div className="flex flex-col h-screen">
      <Navbar menuLinks={menuLinks} key={projectId} />
      {children}
    </div>
  );
}

function PrivateRoutes() {
  updateGAConfigWithUserId();
  const { gqlClient } = useGetGQLClientForSubscription();
  if (!gqlClient) return <PrivateRoute />;
  return (
    <ApolloProvider client={gqlClient}>
      <SubscriptionProvider>
        <Switch>
          <PrivateRoute
            exact
            path={["/home", "/new-project", "/change_password"]}
          >
            <NoMenuLayout>
              <PrivateRoute exact path="/home" component={HomePage} />
              <PrivateRoute
                exact
                path="/new-project"
                component={CreateProjectPage}
              />
              <PrivateRoute
                exact
                path="/change_password"
                component={ChangePassword}
              />
            </NoMenuLayout>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={[
              "/users",
              "/companies",
              "/projects",
              "/settings",
              "/settings/:tab1Id",
              "/settings/:tab1Id/:tab2Id",
              "/settings/:tab1Id/:tab2Id/:tab3Id"
            ]}
          >
            <HomeLayout>
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/companies" component={CompanyList} />
              <PrivateRoute
                exact
                path="/projects"
                component={ProjectsListpage}
              />

              <PrivateRoute
                exact
                path={[
                  "/settings",
                  "/settings/:tab1Id",
                  "/settings/:tab1Id/:tab2Id",
                  "/settings/:tab1Id/:tab2Id/:tab3Id"
                ]}
                component={SubscriptionSettingsPage}
              />
            </HomeLayout>
          </PrivateRoute>
          <PrivateRoute path={["/project/:projectId"]}>
            <ProjectProvider>
              <ProjectLayout>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/project/:projectId/dashboard"
                    component={ProjectDashboardPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/design"
                    component={DesignPage}
                  />
                  {/* <PrivateRoute
                    exact
                    path="/project/:projectId/:logPageType"
                    component={EntityLogPage}
                  /> */}
                  <PrivateRoute
                    exact
                    path="/project/:projectId/submittals"
                    component={SubmittalLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/submittals/:submittalId"
                    component={SubmittalDetailspage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/bid-packages"
                    component={PreconstructionLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/bid-packages/:bidPackageId"
                    component={PreconstructionDetailsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/design-packages"
                    component={DesignPackageLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/design-packages/:designPackageId"
                    component={DesignPackageDetailPage}
                  />
                  <PrivateRoute
                    exact
                    path={[
                      "/project/:projectId/settings",
                      "/project/:projectId/settings/:tab1Id",
                      "/project/:projectId/settings/:tab1Id/:tab2Id",
                      "/project/:projectId/settings/:tab1Id/:tab2Id/:tab3Id"
                    ]}
                    component={ProjectSettingManu}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/materials"
                    component={MaterialListpage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/materials/:materialId"
                    component={MaterialDetailsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/schedule"
                    component={ScheduleMenuTabs}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/reports"
                    component={ReportsPageManu}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/reports/risk"
                    component={RiskReportPage}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:projectId/reports/submittals/export"
                    component={RiskReportExportPDF}
                  />
                  <PrivateRoute
                    path="/project/:projectId/reports/upcoming"
                    component={UpcomingSubmittals}
                  />
                  <PrivateRoute
                    path="/project/:projectId/reports/activities"
                    component={ScheduleActivitiesReport}
                  />

                  <PrivateRoute>
                    <Route component={NotFoundPage} />
                  </PrivateRoute>
                </Switch>
              </ProjectLayout>
            </ProjectProvider>
          </PrivateRoute>
          <PrivateRoute>
            <Route component={NotFoundPage} />
          </PrivateRoute>
        </Switch>
      </SubscriptionProvider>
    </ApolloProvider>
  );
}

function App() {
  // Set the token if it is passed from query string for report services
  setAccessTokenAndSubscriptionId();
  const { inIframe }: any = useContext(AppContext);
  return !inIframe ? (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/set_password" component={SetupAccount} />
        <Route exact path="/reset_password" component={SetPassword} />
        <Route exact path="/forgot_password" component={ForgetPassword} />
        <Route exact path="/accept-invite" component={AcceptInvitePage} />
        <Route exact path="/io/:projectId" component={TinyUrlRedirection} />

        <Route exact path="/admin">
          <AdminApp />
        </Route>

        <Route exact path={PartnerBaseRoute}>
          <PartnerApp />
        </Route>
        <Route component={PrivateRoutes} />
      </Switch>
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route exact path="/io/:projectId" component={TinyUrlRedirection} />
        <Route exact path={PartnerBaseRoute}>
          <PartnerApp />
        </Route>
        <PrivateRoute>
          <Route component={NotFoundPage} />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

// TODO: Need to move to a separate definition file
declare global {
  interface Window {
    JSREPORT_READY_TO_START: any; // this will be your variable name
  }
}

export default App;
