import { AgGridReact } from "ag-grid-react";
import { useContext, useMemo } from "react";
import CiqAgGrid from "components/ciq-ag-grid";
import { GridOptions } from "ag-grid-community";
import { ProjectContext } from "context/ProjectProvider";
import { TLinkedMaterial } from "../models";
import { materialColDef } from "../col-defs/material-col-def";
import { submittalColDefs } from "../col-defs/submittal-col-def";

function MaterialGrid({
  materials,
  gridRef
}: {
  materials: Array<TLinkedMaterial>;
  gridRef: React.RefObject<AgGridReact<TLinkedMaterial>>;
}) {
  const { projectDetails } = useContext(ProjectContext);

  const detailCellRendererParams = useMemo<{
    detailGridOptions: GridOptions;
  }>(() => {
    return {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        defaultColDef: {
          sortable: true,
          filter: "agSetColumnFilter",
          menuTabs: ["filterMenuTab"],
          resizable: true,
          flex: 1
        },
        onGridReady(gridApi) {
          gridApi.api?.sizeColumnsToFit();
        },
        detailRowAutoHeight: true,
        columnDefs: submittalColDefs,
        context: { projectDetails },
        suppressMovableColumns: true
      },

      // get the rows for each Detail Grid
      getDetailRowData: (params: any) => {
        params.successCallback(
          params.data.linked_submittals.map((s: any) => s.submittal)
        );
      }
    };
  }, [projectDetails]);

  const isRowMaster = (data: TLinkedMaterial) =>
    data.linked_submittals?.length > 0;

  const detailRowHeight = (params: { node: any; data: TLinkedMaterial }) => {
    const titleHeight = 50;
    const headerHeight = 50;
    const rowHeight = 42;
    const topPadding = 8;
    const bottomPadding = 30;
    const maxRowCount = 10;
    const isDetailRow = params.node.detail;
    if (!isDetailRow) {
      return undefined;
    }
    const fixedHeight = titleHeight + headerHeight + topPadding + bottomPadding;

    const { data } = params;
    const count = data.linked_submittals.length;
    const minCount = count < maxRowCount ? count : maxRowCount;

    const height = minCount * rowHeight + fixedHeight;
    return height;
  };

  return (
    <div className="h-80">
      <CiqAgGrid
        {...{
          gridRef,
          columnDefs: materialColDef,
          rowData: materials,
          sideBar: null,
          masterDetail: true,
          isRowMaster,
          detailCellRendererParams,
          getRowHeight: detailRowHeight,
          groupDefaultExpanded: 1,
          suppressMovableColumns: true
        }}
      />
    </div>
  );
}
export default MaterialGrid;
