import { SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormRule,
  Input,
  Popover
} from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useState } from "react";
import { QUERY_PROJECT_FEATURE_CONFIGURATION } from "services/graphQL/queries";

function EntityRiskThresholdSetting(props: {
  threshold: {
    id: string;
    high_risk_threshold: number;
    low_risk_threshold: number;
    override_project_threshold: boolean;
  };
  featureTypeId: number;
  actions: { updateFeatureWorkflowInstance: Function };
}) {
  const { threshold, actions, featureTypeId } = props;
  const [overrideProjectThreshold, setOverrideProjectThreshold] = useState(
    threshold.override_project_threshold
  );
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { gqlClientForProject } = useContext(ProjectContext);

  useEffect(() => {
    if (form && threshold) {
      form.setFieldsValue(threshold);
      setOverrideProjectThreshold(threshold.override_project_threshold);
    }
  }, [threshold, form]);

  const {
    data: projectFeatureConfigurationsData,
    refetch: refetchThresholdData
  } = useCIQQuery<{
    project_feature_configurations: Array<any>;
  }>(QUERY_PROJECT_FEATURE_CONFIGURATION, {
    variables: {
      where: { feature_id: { _eq: featureTypeId } }
    },
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: <div className="text-xs">Please enter threshold </div>
    },
    {
      message: (
        <div className="text-xs">
          Please enter threshold in numbers ( + or - ) only.
        </div>
      ),
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      message: (
        <div className="text-[#3B3B3BCC] text-xs">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      warningOnly: true,
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const editThresholdDiv = (
    <div className="w-96">
      <div className="flex  text-xs text-justify">
        Indicate the value of float at which you would consider the workflow to
        be at High, Medium and Low risk.
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          console.log("values", values);
          // console.log("thresholdState", thresholdState);

          setLoading(true);
          let set = {} as any;
          if (values.override_project_threshold) {
            set.override_project_threshold = values.override_project_threshold;
          } else {
            set = values;
          }

          await actions.updateFeatureWorkflowInstance({
            id: threshold.id,
            set
          });
          setLoading(false);
          setVisible(!visible);
        }}
        onReset={() => {
          setVisible(!visible);
        }}
        initialValues={threshold}
        validateTrigger={["onSubmit", "onChange"]}
        disabled={loading}
      >
        <Form.Item name="override_project_threshold" valuePropName="checked">
          <Checkbox
            checked={overrideProjectThreshold}
            onChange={async (event) => {
              setOverrideProjectThreshold(event.target.checked);
              if (event.target.checked) {
                const projectThreshold = projectFeatureConfigurationsData
                  ?.project_feature_configurations.length
                  ? projectFeatureConfigurationsData
                      ?.project_feature_configurations[0]
                  : undefined;
                form.setFields([
                  {
                    name: "high_risk_threshold",
                    value: projectThreshold?.high_risk_threshold,
                    errors: []
                  },
                  {
                    name: "low_risk_threshold",
                    value: projectThreshold?.low_risk_threshold,
                    errors: []
                  }
                ]);
              } else {
                form.setFields([
                  {
                    name: "high_risk_threshold",
                    value: threshold?.high_risk_threshold,
                    errors: []
                  },
                  {
                    name: "low_risk_threshold",
                    value: threshold?.low_risk_threshold,
                    errors: []
                  }
                ]);
              }
            }}
            disabled={loading}
          >
            Use Project Settings
          </Checkbox>
        </Form.Item>

        <Form.Item
          label="HIGH RISK THRESHOLD"
          name="high_risk_threshold"
          rules={thresholdRules}
        >
          <Input
            type="text"
            autoComplete="off"
            disabled={overrideProjectThreshold}
          />
        </Form.Item>
        <Form.Item
          label="LOW RISK THRESHOLD"
          name="low_risk_threshold"
          rules={[
            ...thresholdRules,
            {
              validateTrigger: "onSubmit",
              message: (
                <div className="text-xs">
                  High risk threshold has to be lower than the low risk
                  threshold.
                </div>
              ),
              validator: () => {
                const highRisk = Number(
                  form.getFieldValue("high_risk_threshold")
                );
                const lowRisk = Number(
                  form.getFieldValue("low_risk_threshold")
                );
                if (lowRisk <= highRisk) {
                  return Promise.reject();
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            type="text"
            autoComplete="off"
            disabled={overrideProjectThreshold}
          />
        </Form.Item>
        <Divider className="my-2" />
        <div className="flex w-full justify-end space-x-2">
          <Button htmlType="reset" disabled={loading}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <Popover
      content={editThresholdDiv}
      trigger="click"
      title="RISK THRESHOLD"
      placement="bottomLeft"
      align={{ offset: [-20] }}
      className="p-0"
      open={visible}
      onOpenChange={() => {
        if (refetchThresholdData) refetchThresholdData();
        form.setFieldsValue(threshold);
        setOverrideProjectThreshold(threshold.override_project_threshold);
        setVisible(!visible);
      }}
      destroyTooltipOnHide
    >
      <SettingOutlined />
    </Popover>
  );
}

export default EntityRiskThresholdSetting;
