import LinkIcon from "components/svg-icons/link-icon";
import { ProjectContext } from "context/ProjectProvider";
import useFeatureToFeatureLinks from "entity-app/hooks/feature-to-feature-links";
import { useContext } from "react";

function FeatureLinkItem(props: { name: string; text: string }) {
  const { name, text } = props;
  return (
    <div className="border-solid border-[#3b3b3b80] border-[.5px] rounded-sm p-2">
      <div className="flex items-center space-x-1">
        <LinkIcon />
        <div>{name}</div>
      </div>
      <div className="pt-1 pl-0.5 text-[#3B3B3BCC]">{text}</div>
    </div>
  );
}

function FeatureToFeatureLinksPanel(props: { featureId: string }) {
  const { featureId } = props;
  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;
  const { featureLinks } = useFeatureToFeatureLinks({
    token,
    featureInstanceId: featureId,
    featureTypeId: 5
  });

  return (
    <div className="w-full h-full px-2.5 py-3 overflow-y-auto flex flex-col space-y-2">
      <div className="uppercase text-xs font-medium pb-1">Design packages</div>
      {featureLinks.length > 0 &&
        featureLinks.map((link: any) => {
          const instance = link.source_feature_instance;
          return (
            <FeatureLinkItem
              key={instance.id}
              name={instance.title}
              text={instance.description}
            />
          );
        })}
      {featureLinks.length === 0 && (
        <div className="w-full h-full flex items-center justify-center text-xs italic text-[#3B3B3BCC]">
          No design packages linked
        </div>
      )}
    </div>
  );
}

export default FeatureToFeatureLinksPanel;
