import { Button, Popover, Tooltip } from "antd";
import InlineEditTitle from "components/inline-edit-title";
import { CopyIcon } from "components/svg-icons";
import { useEffect, useState } from "react";
import "./bid-package-header.scss";

function BidPackageHeader(props: {
  actions?: any;
  headerTitle?: string;
  bidNumber?: string;
  status?: any;
}) {
  const { actions, headerTitle, status, bidNumber } = props;
  const [visible, setVisible] = useState(false);
  const [title, setHeaderTitle] = useState<any>(headerTitle);

  const updateTitle = async (value: string) => {
    setHeaderTitle(bidNumber ? `${bidNumber} - ${value}` : value);
    await actions.updateFeatureInstanceFields({
      title: value
    });
  };

  useEffect(() => {
    setHeaderTitle(bidNumber ? `${bidNumber} - ${headerTitle}` : headerTitle);
  }, [headerTitle, bidNumber]);

  return (
    <div className="flex justify-between items-center w-full p-2 bid-package-header">
      <InlineEditTitle isEditable title={title} updateTitle={updateTitle} />
      <div className="flex space-x-2 items-center">
        <div className="label uppercase">{status}</div>
        <Popover
          open={visible}
          placement="bottomRight"
          // content={<div>Test</div>}
          trigger="click"
          onOpenChange={() => {
            setVisible(!visible);
          }}
          destroyTooltipOnHide
        >
          <Tooltip>
            <Button onClick={() => setVisible(true)} disabled>
              <div className="flex items-center space-x-2">
                <div className=" text-[12px]">Duplicate</div>
                <CopyIcon />
              </div>
            </Button>
          </Tooltip>
        </Popover>
      </div>
    </div>
  );
}

export default BidPackageHeader;
