import { useCallback, useMemo } from "react";
import { Input } from "antd";
import debounce from "lodash.debounce";
import { IconSearch } from "@tabler/icons-react";
import { AgGridReact } from "ag-grid-react";

type SearchInputProps = {
  placeholder?: string;
  gridRef: React.RefObject<AgGridReact<any>>;
  disabled?: boolean;
};

function CiqAgSearch(props: SearchInputProps) {
  const { gridRef, placeholder = "Search", disabled = false } = props;

  const debouncedSearch = useMemo(
    () =>
      debounce((text) => {
        gridRef.current?.api?.setQuickFilter(text);
      }, 500),
    [gridRef]
  );

  const handleChange = useCallback(
    (e: React.FormEvent) => {
      debouncedSearch((e.target as HTMLInputElement).value);
    },
    [debouncedSearch]
  );

  return (
    <div className="w-[225px] py-1">
      <Input
        type="text"
        placeholder={placeholder}
        suffix={<IconSearch size={14} />}
        onChange={handleChange}
        disabled={disabled || !gridRef.current?.api}
      />
    </div>
  );
}

export default CiqAgSearch;
